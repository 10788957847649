import React, { Fragment, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  Drawer,
  Divider,
  Paper,
  Avatar,
  Typography,
  Button,
  CardHeader
} from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import useRouter from "utils/useRouter";
import { Navigation, GenericMoreButton } from "components";
import navigationConfig from "./navigationConfig";
import InputIcon from "@material-ui/icons/Input";
import { logout } from "actions";
import { PageLoaderContext } from "context/Page";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    overflowY: "auto"
  },
  action: {
    marginRight: 0,
    marginTop: 0,
    alignSelf: "center"
  },
  companySectionTitle: {
    color: "#000",
    fontSize: 16,
    fontWeight: 500
  },
  companySection: {
    width: "100%",
    display: "flex",
    padding: 0
  },
  userSection: {
    // position: 'absolute',
    position: "fixed",
    bottom: "0",
    left: "0",
    // width: '100%',
    display: "flex",
    padding: "16px",
    marginBottom: "5px",
    zIndex: 5,
    width: 270,
    minWidth: 270,
    backgroundColor: "#fff"
  },
  userSectionTitle: {
    color: "#000",
    fontSize: 14,
    fontWeight: 500
  },
  content: {
    padding: theme.spacing(2)
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2),
    marginBottom: 50
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  }
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const session = useSelector(state => state.session);
  const dispatch = useDispatch();
  const { setPageLoading } = useContext(PageLoaderContext);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const handleLogout = () => {
    setPageLoading(true);
    dispatch(logout());
    setPageLoading(false);
  };

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <CardHeader
          className={classes.companySection}
          classes={{
            action: classes.action,
            title: classes.companySectionTitle
          }}
          avatar={
            <Avatar
              aria-label="recipe"
              alt="Company"
              variant="square"
              src="/images/avatars/mk-farming.png"
            ></Avatar>
          }
          action={<GenericMoreButton />}
          title="MK AGRI"
        />
        <Typography className={classes.name} variant="h4">
          {session.user.first_name} {session.user.last_name}
        </Typography>
        <Typography variant="body2">{session.user.bio}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
      <CardHeader
        className={classes.userSection}
        classes={{ action: classes.action, title: classes.userSectionTitle }}
        avatar={
          <Avatar
            aria-label="recipe"
            alt="Person"
            src="/images/avatars/avatar_13.jpeg"
          ></Avatar>
        }
        action={<GenericMoreButton />}
        title="Branimir Popov"
        subheader="Director at Agtrinity"
      />
      <Hidden lgUp>
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
        >
          <InputIcon className={classes.logoutIcon} />
          Sign out
        </Button>
      </Hidden>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
