import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M44.1,31.9l0-3.9c0-3.1,0-6.1,0-9.2c0-0.6,0-0.7,0.5-0.9c0.9-0.4,1.5-1.2,1.7-1.6c0.8-1.4,0.5-3.3-0.8-4.4
	c-1.3-1.1-3.1-1.2-4.6-0.2l-0.2,0.1c-0.2,0.2-0.2,0.2-0.4,0.1c-1.9-1.1-3.8-2.2-5.6-3.3c-2-1.1-3.9-2.3-5.9-3.4
	c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-1.6-0.5-2.9-1.8-3.7c-1.2-0.8-2.6-0.8-3.9-0.1c-1.3,0.8-1.9,2.1-1.8,3.7c0,0.1-0.1,0.3-0.1,0.4
	c-3.2,1.9-6.4,3.7-9.6,5.6l-1.9,1.1c-1.3-1.1-2.7-1.3-4.2-0.6c-1.4,0.6-2.1,1.9-2,3.4c0,1.6,0.9,2.8,2.4,3.4c0.1,0,0.1,0.2,0.1,0.3
	c0,4.7,0,9,0,13.2c0,0.1-0.1,0.3-0.2,0.3c-1.1,0.5-1.9,1.4-2.2,2.5c-0.3,1-0.2,2,0.3,2.9C4.2,38.1,5,38.7,6,38.9
	c1.1,0.3,2.2,0,3.3-0.7c0.1,0,0.3,0,0.3,0c3.9,2.3,7.8,4.5,11.4,6.7c0.1,0.1,0.2,0.3,0.2,0.3c-0.2,1.6,0.4,2.8,1.7,3.7
	c0.6,0.4,1.3,0.6,2,0.6c0.6,0,1.3-0.2,1.9-0.5c1.3-0.8,1.9-2,1.8-3.7c0-0.1,0.1-0.4,0.2-0.4c1.6-1,3.2-1.9,4.9-2.8l1-0.6
	c1.4-0.8,2.9-1.6,4.3-2.4l1.4-0.8c1.2,0.8,2.3,1.1,3.4,0.8c0.9-0.2,1.7-0.8,2.3-1.6c0.6-1,0.7-2.1,0.4-3.2
	C46,33.2,45.2,32.4,44.1,31.9z M24.9,47.3c-0.8,0-1.5-0.7-1.5-1.5c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.4,1.1-0.4
	c0.8,0,1.5,0.8,1.4,1.6C26.4,46.7,25.7,47.4,24.9,47.3z M6.9,37c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.4-1.5c0,0,0,0,0,0
	c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1C8.3,36.3,7.7,37,6.9,37C6.9,37,6.9,37,6.9,37z M24.9,2.8C24.9,2.8,24.9,2.8,24.9,2.8
	c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5c-0.4,0-0.8-0.2-1.1-0.4c-0.3-0.3-0.5-0.7-0.5-1.1c0-0.4,0.2-0.7,0.4-1
	C24.1,3,24.5,2.8,24.9,2.8z M32.4,26c-0.1-0.1-0.2-0.2-0.4-0.2c-0.6-0.1-1.1-0.1-1.7-0.2c-1.3-0.1-2.6-0.3-3.8-0.3
	c-1.2,0-1.7-0.4-2.1-1.5c-0.1-0.2-0.2-0.4-0.2-0.7c0.2,0.3,0.3,0.7,0.5,1c0.2,0.4,0.7,0.7,1.1,0.8c1.9,0.2,3.9,0.4,5.9,0.6l1.8,0.2
	c0.2,0,0.3,0,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5l-0.1-1c-0.1-0.8-0.2-1.6-0.2-2.3c0-0.2,0-0.4,0-0.5c1.9-1.1,3.7-2.2,5.6-3.3l1.1-0.7
	c0.2,0.1,0.3,0.3,0.6,0.3c0.8,0.2,0.9,0.6,0.9,1.7l0,0.3c0,3.1,0,6.1,0,9.2l0,3.1l-1.4,0.8l-1.4-0.8c-2.2-1.3-4.4-2.6-6.6-3.8
	c0,0-0.1-0.2-0.1-0.4c0-0.2,0-0.5,0.1-0.8c0-0.2,0-0.3,0.1-0.5C32.5,26.2,32.5,26.1,32.4,26z M22.3,42.9c-3.9-2.2-7.8-4.4-11.5-6.5
	c0,0-0.1-0.2-0.1-0.3l0,0c0-0.1,0.1-0.3,0-0.5c-0.3-0.8,0-1,0.9-1.5l0.3-0.2c1.4-0.8,2.7-1.6,4.1-2.4l1.3-0.8
	c1.5,2.2,3.7,3.5,6.4,3.8v7.8c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.2-0.7,0.4-1,0.5C22.5,43,22.3,43,22.3,42.9z M21.4,7.6l0.5-0.3
	c0.2-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.2c0.9,0.4,0.9,0.8,0.9,1.9c0,2.2,0,4.3,0,6.3c0,0.3,0,0.3,0,0.3c0,0,0,0-0.2,0.1
	c-0.5,0.1-0.9,0.2-1.3,0.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0.1-0.3,0.2-0.3,0.3c-0.1,0.1-0.1,0.3,0,0.5l0.7,1.5
	c-0.1-0.2-0.3-0.3-0.5-0.4c-0.5-0.2-0.9,0.1-1.1,0.2c-0.1,0.1-0.3,0.2-0.4,0.2c-0.3,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.5,0.4-1.1,0
	c-2.3-1.4-4.8-2.8-7.1-4.1c-0.2-0.1-0.3-0.2-0.3-0.8c-0.2-1,0.3-1.2,1.3-1.8C15.1,11.3,18.3,9.4,21.4,7.6z M27.8,7.2
	c3.8,2.1,7.5,4.3,11.3,6.5c0.1,0.1,0.2,0.3,0.2,0.3c-0.1,0.3,0,0.6,0,0.9c0.1,0.4,0.1,0.5-0.1,0.6c-1.7,1-3.3,1.9-5,2.9l-1.6,0.9
	c-1.6-2.2-3.8-3.5-6.4-3.7c0,0,0,0,0-0.1l0-0.7c0-2.3,0-4.5,0-6.8c0-0.3,0.2-0.6,0.6-0.8C27.1,7.1,27.5,7.1,27.8,7.2z M8,31.8l0-8.2
	c0-1.6,0-3.2,0-4.9c0-0.6,0.1-0.7,0.4-0.8c0.4-0.1,0.7-0.3,0.9-0.6c0,0,0.1,0,0.1-0.1l7.7,4.5c0,0,0,0.1-0.1,0.1
	c-0.2,0.4-0.4,0.8-0.6,1.2c-0.7,1.7-0.8,3.5-0.3,5.2c0.1,0.4,0.1,0.4-0.2,0.6c-2.2,1.3-4.4,2.6-6.2,3.7c-0.4,0.3-0.4,0.2-0.6,0
	c-0.3-0.3-0.6-0.5-1-0.6C8.1,31.9,8.1,31.9,8,31.8z M18.2,25.8c0.1-1.9,1-3.4,2.6-4.6l0.2,0.5c0.7,1.5,1.3,2.9,2,4.2
	c-0.9,1.3-1.8,2.5-2.7,3.9L20,30.3C18.7,29.1,18.1,27.6,18.2,25.8z M22.1,31.1c0.9-1.2,1.7-2.4,2.6-3.5c0,0,0.1-0.1,0.1-0.1
	c1.4,0.1,2.9,0.2,4.4,0.3l0.8,0.1c-0.5,1.7-1.5,2.9-3,3.7c-1.8,0.9-3.5,0.9-5.3,0.1L22.1,31.1z M31.5,23.3l-0.6-0.1
	c-1.5-0.1-3-0.3-4.5-0.4c0,0-0.1-0.1-0.1-0.1c-0.6-1.3-1.3-2.7-1.9-4.1l-0.3-0.6c1.7-0.4,3.7,0,5.2,1.1
	C30.7,20.3,31.5,21.8,31.5,23.3z M43,16.2c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.6-0.5-1c0-0.9,0.7-1.5,1.6-1.6c0,0,0,0,0,0
	c0.4,0,0.7,0.2,1,0.4c0.3,0.3,0.4,0.7,0.4,1.1C44.5,15.5,43.8,16.2,43,16.2L43,16.2z M6.9,13.1c0.9,0,1.5,0.7,1.5,1.6
	c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.5-1,0.5c-0.4,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.1C5.4,13.8,6.1,13.1,6.9,13.1
	C6.9,13.1,6.9,13.1,6.9,13.1z M27.3,42.9c-0.3-0.2-0.7-0.4-1-0.6l-0.2-0.1v-7.9c0.3-0.1,0.5-0.2,0.8-0.4c0.8-0.4,1.7-0.8,2.4-1.3
	c0.7-0.5,1.3-1.2,1.9-2c0.2-0.2,0.3-0.4,0.5-0.6l5.7,3.3l0.1,0.1c0.1,0.1,0.3,0.2,0.5,0.3c1.2,0.3,1.3,1,1.3,2.5
	c0,0-0.1,0.1-0.2,0.2c-3.8,2.2-7.7,4.4-11.5,6.6C27.6,43,27.4,43,27.3,42.9z M42.9,36.9c-0.4,0-0.7-0.2-1-0.4
	c-0.3-0.3-0.5-0.8-0.5-1.2c0-0.7,0.8-1.4,1.5-1.4c0,0,0,0,0,0c0.8,0,1.5,0.6,1.5,1.5c0,0.4-0.1,0.8-0.4,1.1
	C43.8,36.7,43.4,36.9,42.9,36.9C42.9,36.9,42.9,36.9,42.9,36.9z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
