/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';
import AuthLayout from 'layouts/Auth';
import ErrorLayout from 'layouts/Error';
import DashboardLayout from 'layouts/Dashboard';
import DashboardAnalyticsView from 'views/DashboardAnalytics';
import DashboardDefaultView from 'views/DashboardDefault';
import OverviewView from 'views/Overview';
import PresentationView from 'views/Presentation';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/presentation"/>,
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login')),
      },
      {
        component: () => <Redirect to="/errors/error-404"/>,
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500')),
      },
      {
        component: () => <Redirect to="/errors/error-404"/>,
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar')),
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView,
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView,
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails')),
      },
      {
        path: '/management/map',
        exact: true,
        component: lazy(() => import('views/Map')),
      },
      {
        path: '/management/soil-maps',
        exact: true,
        component: lazy(() => import('views/SoilMaps')),
      },
      {
        path: '/management/plant-data',
        exact: true,
        component: lazy(() => import('views/PlantData')),
      },
      {
        path: '/management/yield-data',
        exact: true,
        component: lazy(() => import('views/YieldData')),
      },
      {
        path: '/management/lab-data',
        exact: true,
        component: lazy(() => import('views/LabData')),
      },
      {
        path: '/management/lab-data/:formGroup/:formPath',
        exact: true,
        component: lazy(() => import('views/LabData')),
      },
      {
        path: '/management/season',
        exact: true,
        component: lazy(() => import('views/Season')),
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView,
      },
      {
        path: '/presentation',
        exact: true,
        component: PresentationView,
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        component: () => <Redirect to="/errors/error-404"/>,
      },
    ],
  },
];

export default routes;
