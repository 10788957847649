import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M49.2,46.7c-0.1-0.2-0.1-0.4-0.1-0.6l-2-5.8c-2-5.5-3.9-11-5.8-16.5c-0.5-1.5-1.7-1.5-2.4-1.5
				c-9,0-18.1,0-27.1,0c-1.4,0-2.1,0.5-2.6,1.8c-1.8,5.1-3.7,10.1-5.5,15.2l-2.3,6.4c-0.1,0.3-0.2,0.5-0.2,0.8l-0.4,1.6h49
				L49.2,46.7z M2.9,46.4L5.2,40C7,35,8.9,29.9,10.7,24.8c0.2-0.6,0.3-0.6,0.9-0.6c9,0,18.1,0,27.1,0c0.6,0,0.6,0,0.7,0.3
				c1.9,5.5,3.9,11,5.8,16.5l1.9,5.4H2.9z"
          />
          <polygon
            className="st0"
            points="23.9,43.4 26.4,43.4 26.1,37.6 23.9,37.6 			"
          />
          <rect x="23.9" y="26.6" className="st0" width="1.9" height="5.8" />
          <path
            className="st0"
            d="M34.7,7.7c-1.2,0.7-2.2,1.3-3.3,2c-6.1,3.6-12.2,7.2-18.3,10.8c-0.7,0.4-1.5,0.5-2.3,0.7
				c-0.1-0.2-0.1-0.3-0.2-0.5c0.3-0.3,0.7-0.6,1-0.8c7-4.2,14-8.3,21-12.5C33.5,7,34,6.9,34.7,7.7z"
          />
          <path
            className="st0"
            d="M14.9,20.9c0.3-0.2,0.5-0.4,0.8-0.6c6.4-3.8,12.9-7.6,19.3-11.5c0.7-0.4,1.3-0.6,2,0.2
				c-0.3,0.2-0.6,0.4-1,0.6c-6.1,3.6-12.2,7.3-18.3,10.8C16.9,21,15.9,21,15,21.3C14.9,21.1,14.9,21,14.9,20.9z"
          />
          <path
            className="st0"
            d="M32.4,6.4c-6.9,4.1-13.7,8.2-20.6,12.2c-0.1-0.1-0.2-0.2-0.3-0.3c0.6-0.6,1.1-1.5,1.8-1.9
				c5.7-3.4,11.4-6.8,17.1-10.2C31.1,5.7,31.7,5.5,32.4,6.4z"
          />
          <path
            className="st0"
            d="M39.2,10.5c-2.7,1.6-5.4,3.2-8.1,4.8c-2.9,1.7-5.8,3.5-8.7,5.1c-0.8,0.5-1.9,0.6-2.8,0.9
				c-0.1-0.1-0.1-0.3-0.2-0.4c0.3-0.2,0.5-0.4,0.8-0.5c5.7-3.4,11.4-6.7,17-10.1C38,9.8,38.6,9.6,39.2,10.5z"
          />
          <path
            className="st0"
            d="M25.4,2.3c-0.2,0.1-0.3,0.3-0.5,0.4C19.2,6.1,13.4,9.5,7.5,12.9c-0.7,0.4-1.5,0.4-2.3,0.6
				c0-0.1-0.1-0.2-0.1-0.3c0.2-0.2,0.4-0.4,0.6-0.5c6-3.6,11.9-7.1,17.9-10.6c0.4-0.3,1.1-0.2,1.7-0.2C25.4,2,25.4,2.1,25.4,2.3z"
          />
          <path
            className="st0"
            d="M27.8,3.7c-5.3,3.2-10.6,6.3-15.9,9.4c-0.6,0.4-1.5,0.2-2.3,0.1c0.2-0.2,0.5-0.4,0.7-0.5
				c5.2-3.1,10.3-6.1,15.5-9.2C26.5,3,27.1,2.8,27.8,3.7z"
          />
          <path
            className="st0"
            d="M13,14.6c0.5-0.6,0.9-1.4,1.6-1.8c4.5-2.8,9.1-5.4,13.6-8.1c0.8-0.5,1.3-0.5,1.9,0.3
				c-5.6,3.3-11.2,6.6-16.7,9.9C13.2,14.9,13.1,14.7,13,14.6z"
          />
          <path
            className="st0"
            d="M35.6,13.9c0.7,0.8,0.5,1.2-0.3,1.7c-2.8,1.6-5.6,3.4-8.5,5C26,21,25,21.1,24.2,21.3
				c-0.1-0.2-0.1-0.3-0.2-0.5C27.8,18.5,31.7,16.3,35.6,13.9z"
          />
          <path
            className="st0"
            d="M28.6,20.8c2.6-1.6,5.2-3.1,7.9-4.7c0.2,0.4,0.4,0.7,0.6,1.2c-2.9,1.1-5,3.9-8.4,3.8
				C28.7,21.1,28.6,21,28.6,20.8z"
          />
          <path
            className="st0"
            d="M32.9,21c1.6-1,3-1.8,4.5-2.7c0.2,0.4,0.3,0.7,0.4,0.9C37.6,20.1,34.7,21.2,32.9,21z"
          />
          <path
            className="st0"
            d="M41.5,11.8c-1.3,0.9-2.6,2-4.3,1.4C38.7,11.6,40.5,11,41.5,11.8z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
