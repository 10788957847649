import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M5.9,22.4c-1.1,0.8-2,1.4-2.9,2.1C2.5,24.9,2,25,1.4,24.7c-1-0.5-1.2-1.7-0.4-2.4c0.4-0.4,0.8-0.7,1.2-1
			C9.3,16.1,16.4,11,23.5,5.8c1.3-1,1.6-1,3,0c7.3,5.3,14.6,10.6,21.9,16c0.2,0.1,0.3,0.2,0.5,0.4c0.7,0.6,0.8,1.4,0.3,2.1
			c-0.5,0.7-1.4,0.9-2.1,0.4c-0.8-0.5-1.6-1.2-2.4-1.7c-0.2-0.1-0.3-0.2-0.6-0.4c0,0.4,0,0.6,0,0.9c0,6.5,0,13.1,0,19.6
			c0,1.5-0.5,2-2,2c-3.9,0-7.7,0-11.6,0c-1.3,0-1.8-0.5-1.8-1.9c0-4.3,0-8.7,0-13c0-0.3,0-0.6,0-0.9c-2.4,0-4.8,0-7.3,0
			c0,0.3,0,0.6,0,0.9c0,4.3,0,8.7,0,13c0,1.4-0.5,1.9-1.9,1.9c-3.8,0-7.7,0-11.5,0c-1.5,0-2-0.5-2-2c0-6.5,0-13,0-19.5
			C5.9,23.1,5.9,22.8,5.9,22.4z M9,41.8c3.2,0,6.2,0,9.3,0c0-0.3,0-0.6,0-0.8c0-4.4,0-8.7,0-13.1c0-1.4,0.6-1.9,2-1.9
			c3.2,0,6.3,0,9.5,0c1.4,0,1.9,0.5,1.9,1.9c0,4.4,0,8.7,0,13.1c0,0.3,0,0.6,0,0.9c3.2,0,6.2,0,9.3,0c0-0.2,0-0.4,0-0.6
			c0-6.8,0-13.7,0-20.5c0-0.3-0.3-0.7-0.5-0.9c-4.9-3.6-9.9-7.2-14.8-10.8c-0.2-0.2-0.4-0.3-0.7-0.5C19.8,12.3,14.7,16,9.6,19.7
			C9.2,20,9,20.3,9,20.9c0,6.7,0,13.4,0,20C9,41.2,9,41.5,9,41.8z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
