import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M5.1,13.5c0.4,0.1,0.8,0.5,1.1,0.7c-0.4,0.2-0.9,0.5-1.3,0.6c-0.8,0.1-1.6-0.1-2.4,0.1
		c-0.6,0.1-1.2,0.4-1.8,0.6C1.3,15.6,1.9,16,2.5,16c15,0,30,0,45,0c0.3,0,0.6,0.1,0.9,0c0.3-0.1,0.6-0.3,0.9-0.5
		c-0.3-0.2-0.6-0.6-1-0.6c-1.2-0.1-2.3,0-3.5-0.1c-0.5,0-0.9-0.3-1.3-0.5c0.4-0.2,0.8-0.6,1.3-0.7c2.6-0.5,4.2-2,4.5-4.6
		c0.3-2.2,0.1-2.4-2.1-2.2c-0.5,0.1-1,0.1-1.6,0.2c0.5-2.4-0.4-4.2-1.9-5.8c-0.6-0.7-1.1-0.7-1.8,0c-1.4,1.6-2.3,3.3-1.9,5.6
		c-0.8-0.1-1.4-0.2-2-0.3c-1.1-0.2-1.6,0.2-1.5,1.4c0.1,3.1,1.6,5,4.7,5.7c0.4,0.1,0.7,0.5,1.1,0.7c-0.4,0.2-0.8,0.5-1.2,0.5
		c-4.7,0-9.5,0-14.2,0c-0.4,0-0.9-0.3-1.3-0.5c0.4-0.2,0.8-0.6,1.3-0.7c2.6-0.5,4.1-1.9,4.5-4.5c0.4-2.3,0.1-2.6-2.2-2.3
		c-0.4,0.1-0.9,0.1-1.4,0.2C28,4.8,27.4,3,26.1,1.5c-1.1-1.2-1.4-1.2-2.5,0.1c-1.2,1.5-1.8,3.1-1.5,5.2c-0.6-0.1-1-0.2-1.4-0.2
		c-2-0.3-2.3,0-2.1,1.9c0.2,2.8,1.8,4.5,4.5,5c0.5,0.1,0.8,0.5,1.3,0.7c-0.4,0.2-0.9,0.5-1.3,0.5c-4.7,0-9.4,0-14,0
		c-0.5,0-0.9-0.3-1.4-0.5C8,14,8.3,13.6,8.8,13.5c2.6-0.5,4-1.8,4.5-4.3c0.5-2.5,0.2-2.8-2.3-2.5c-0.4,0.1-0.8,0.1-1.4,0.2
		c0.5-2.3-0.4-4.1-1.8-5.7c-0.7-0.7-1.2-0.7-1.9,0C4.5,2.8,3.7,4.5,4.1,6.8C3.4,6.7,2.9,6.6,2.4,6.5c-1.7-0.2-2,0-1.9,1.8
		C0.7,11.2,2.2,12.9,5.1,13.5z M48,8c0.2,2.8-1.4,4.3-4.3,4.2C43.6,9.5,45.2,7.8,48,8z M37.9,7.8c2.8,0,4.2,1.4,4.3,4.4
		C39.2,12.2,37.7,10.6,37.9,7.8z M42.8,2.1c2.3,2.3,2.3,4.4,0.1,6.6C40.7,6.7,40.6,4.6,42.8,2.1z M30,8c0.3,2.6-1.3,4.3-4.4,4.3
		C25.8,9.3,27.2,7.9,30,8z M19.9,7.8c2.8,0.1,4,1.2,4.4,4.4C21.4,12.4,19.6,10.6,19.9,7.8z M24.8,2.2c2.3,2,2.3,4.6,0,6.6
		C22.7,6.5,22.7,4.4,24.8,2.2z M12,8c0.2,2.7-1.4,4.3-4.4,4.2C7.8,9.2,9.3,7.9,12,8z M6.9,2.2c2.2,2.1,2.2,4.3,0,6.6
		C4.7,6.4,4.7,4.2,6.9,2.2z M1.9,7.8c2.8,0.1,4,1.3,4.3,4.3C3.7,12.7,1.6,10.6,1.9,7.8z"
          />
          <path
            className="st0"
            d="M44.7,48.1c-0.4,0-0.8-0.3-1.2-0.5c0.4-0.2,0.7-0.7,1.1-0.7c2.2-0.4,3.8-1.5,4.5-3.7c0.9-3,0.3-3.6-2.7-3
		c-0.2,0-0.4,0.1-0.8,0.1c0.4-2.2-0.3-3.9-1.6-5.5c-0.8-1-1.4-1.1-2.3,0c-1.2,1.6-2,3.2-1.6,5.4c-0.8-0.1-1.4-0.2-2.1-0.3
		c-1.1-0.2-1.6,0.2-1.5,1.4c0.1,3.1,1.5,4.9,4.5,5.6c0.4,0.1,0.8,0.5,1.2,0.8c-0.4,0.2-0.9,0.5-1.3,0.5c-4.7,0-9.4,0-14,0
		c-0.5,0-0.9-0.3-1.4-0.5c0.4-0.3,0.8-0.7,1.2-0.8c2.1-0.4,3.7-1.3,4.3-3.4c0.3-1,0.2-2.2,0.2-3.3c-1,0-2,0-3,0
		c-0.1,0-0.3,0-0.7,0.1c0.5-2.2-0.3-4-1.7-5.6c-0.8-1-1.4-0.8-2.2,0.1c-1.3,1.6-2,3.2-1.6,5.4c-0.8-0.1-1.4-0.2-2.1-0.3
		c-1.1-0.2-1.6,0.2-1.5,1.4c0.1,3.1,1.5,4.9,4.5,5.6c0.5,0.1,0.8,0.5,1.2,0.8c-0.4,0.2-0.9,0.6-1.3,0.6c-4.7,0-9.4,0-14,0
		c-0.5,0-0.9-0.3-1.4-0.5c0.4-0.3,0.8-0.7,1.2-0.8c3.1-0.7,4.6-2.4,4.7-5.5c0-1-0.2-1.6-1.3-1.4c-0.8,0.1-1.6,0.2-2.4,0.2
		c0.4-2.3-0.4-4.2-1.9-5.8c-0.7-0.7-1.2-0.6-1.8,0c-1.4,1.6-2.3,3.3-1.8,5.6c-0.7-0.1-1.3-0.2-1.9-0.3c-1.2-0.2-1.8,0.1-1.7,1.5
		c0.1,3,1.6,4.8,4.5,5.5c0.5,0.1,0.8,0.5,1.3,0.8c-0.5,0.2-1,0.5-1.5,0.6c-0.9,0.1-1.8,0-2.7,0.1c-0.5,0-0.9,0.4-1.4,0.6
		c0.4,0.2,0.9,0.4,1.3,0.6c0.1,0.1,0.3,0,0.5,0c15,0,30,0,45,0c0.4,0,0.7,0.1,1.1,0c0.3-0.1,0.5-0.5,0.8-0.7
		c-0.3-0.2-0.5-0.4-0.8-0.5C47.3,48.1,46,48.2,44.7,48.1z M12,41.3c0.1,2.7-1.3,4.2-4.3,4.3C7.4,42.9,9.2,41.1,12,41.3z M1.9,41.2
		c2.8-0.1,4.2,1.2,4.4,4.4C3.2,45.5,1.7,44,1.9,41.2z M6.9,35.5c2.2,2.1,2.3,4.3,0,6.7C4.7,39.7,4.7,37.7,6.9,35.5z M30.1,41.3
		c0,2.8-1.2,4.1-4.3,4.4C25.2,43.2,27.1,41.3,30.1,41.3z M19.9,41c3.1,0.4,4.2,1.6,4.3,4.5C21.4,45.7,19.7,44,19.9,41z M24.8,35.4
		c2.3,2.3,2.3,4.5,0.1,6.6C22.7,40.1,22.6,37.9,24.8,35.4z M48,41.2c0.5,2.6-1.6,4.7-4.3,4.3C43.8,42.7,45.1,41.3,48,41.2z
		 M37.9,41.1c3,0.1,4.3,1.5,4.2,4.5C39,45.3,37.6,43.8,37.9,41.1z M42.8,35.5c2.3,1.9,2.4,4.5,0.1,6.6C40.7,40,40.6,37.9,42.8,35.5z
		"
          />
          <path
            className="st0"
            d="M5.2,30.2C5.6,30.3,6,30.7,6.4,31c-0.1,0.2-0.2,0.3-0.2,0.5c-1.5,0-3,0-4.6,0c-0.3,0-0.6,0.4-0.9,0.6
		c0.3,0.2,0.5,0.5,0.9,0.6c0.3,0.1,0.7,0,1.1,0c5.8,0,11.6,0,17.4,0c9.4,0,18.7,0,28.1,0c0.4,0,0.9-0.4,1.3-0.6
		c-0.4-0.2-0.8-0.5-1.2-0.5c-1.5-0.1-3,0-4.5,0c-0.1-0.2-0.1-0.4-0.2-0.6c0.4-0.2,0.8-0.6,1.3-0.7c3-0.6,4.7-2.5,4.8-5.6
		c0-1.1-0.4-1.5-1.4-1.4c-0.7,0.1-1.5,0.2-2.4,0.3c0.5-2.2-0.3-3.9-1.6-5.5c-0.8-1-1.4-1.1-2.3,0c-1.3,1.6-2,3.2-1.6,5.4
		c-0.7-0.1-1.4-0.2-2-0.3c-1.2-0.2-1.6,0.2-1.6,1.5c0.1,3.1,1.6,4.9,4.8,5.6c0.9,0.2,0.9,0.2,0.7,1.2c-5.5,0-11,0-16.4,0
		c-0.1-0.2-0.2-0.3-0.2-0.5c0.4-0.2,0.8-0.6,1.2-0.7c3.1-0.6,4.8-2.6,4.8-5.7c0-0.9-0.3-1.3-1.2-1.2c-0.8,0.1-1.6,0.2-2.6,0.3
		c0.4-2.3-0.4-4.2-1.9-5.8c-0.6-0.7-1.1-0.7-1.8,0c-1.5,1.6-2.3,3.4-1.9,5.6c-0.7-0.1-1.3-0.2-1.9-0.3c-1.3-0.2-1.7,0.3-1.6,1.6
		c0.2,3,1.6,4.7,4.8,5.5c0.4,0.1,0.7,0.5,1.1,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-5.5,0-11,0-16.4,0c-0.1-0.1-0.2-0.3-0.3-0.4
		c0.4-0.3,0.8-0.7,1.2-0.7c3.1-0.6,4.8-2.6,4.9-5.7c0-1-0.4-1.4-1.3-1.3c-0.8,0.1-1.6,0.2-2.5,0.3c0.4-2.3-0.4-4.1-1.9-5.7
		c-0.7-0.7-1.2-0.7-1.9,0c-1.3,1.6-2.2,3.3-1.8,5.6c-0.8-0.1-1.5-0.2-2.1-0.3c-1.2-0.2-1.6,0.3-1.5,1.5C0.7,27.7,2.1,29.5,5.2,30.2z
		 M48,24.6c0.2,2.7-1.3,4.3-4.4,4.4C43.6,26.1,45.2,24.6,48,24.6z M42.1,29c-2.9-0.2-4.2-1.5-4.2-4.4C40.5,24.1,42.5,26.3,42.1,29z
		 M42.8,18.9c2.3,1.7,2.4,4.4,0.1,6.6C40.7,23,40.7,21.3,42.8,18.9z M30.1,24.6c0,3-1.6,4.5-4.4,4.3C25.6,26.2,27,24.7,30.1,24.6z
		 M19.9,24.6c2.7-0.3,4.1,1.2,4.4,4.3C21.3,29,19.8,27.4,19.9,24.6z M24.8,18.8c2.3,1.9,2.2,5,0.2,6.6
		C22.7,23.5,22.6,21.4,24.8,18.8z M12,24.6c0.6,2.4-1.8,4.8-4.3,4.3C7.6,26.1,9.1,24.6,12,24.6z M6.8,18.9c2.3,1.7,2.4,4.4,0.1,6.6
		C4.7,23.1,4.7,21.3,6.8,18.9z M6.2,28.9c-2.7,0.2-4.3-1.4-4.3-4.4C4.7,24.5,6.2,26,6.2,28.9z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
