import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M43.9,41.3c-0.8-0.6-1.7-1.3-2.6-1.9c-0.6-0.4-1.3-0.8-1.9-1.2c1.6-0.9,2.3-1.4,3.8-2.3
		c-2.2,0.1-4.2,0.7-6.3,1.2c-0.1,0-0.1,0-0.2-0.1l-0.4-0.7c0.2,0,0.4-0.1,0.6-0.1c2.7-0.6,5.3-1.5,8.1-1.3c1.6-0.8,2.5-1.2,4.3-1.5
		c-3.4-1.1-6.1,0.3-9.2,0.9c-1.5,0.3-3.2,0.3-5,0.2l-5.8-9.7c0,0,0-0.1,0-0.2c0-2.8,0-5.6,0-8.4c0.1,0,0.2,0,0.3,0
		c0.9,0,1.7-0.7,1.9-1.6c0.2-0.9-0.1-1.8-0.9-2.3c-0.4-0.3-0.9-0.3-1.3-0.3c-1.8,0-3.5,0-5.3,0h-3.5c-1.1,0-2,0.8-2.1,1.9
		c-0.1,1.1,0.7,2.1,1.8,2.2c0.1,0,0.3,0,0.5,0l0,0.9c0,2.5,0,5,0,7.5c0,0,0,0.1,0,0.2l-4.4,8.5c-1.2-0.7-1.9-1.2-3.4-2.2
		c0.8,1.3,1.7,2.6,2.6,3.8l-0.6,1.3c-1.5-2-2.8-4.1-4.1-6.3c-2.1-1-4.2-1.8-6.3-2.7c2.9,3.5,5.1,7.6,8.1,11c-0.7,0-1.4,0-2.4,0
		l-0.6,0l0.6,0.2c1,0.3,2.1,0.6,3.1,1c-0.1,0.2-0.2,0.4-0.3,0.7c-1.9-0.6-3.7-1.1-5.4-1.7h0h0c-2.2,0.4-4.4,1-6.5,1.6l-0.4,0.1
		l0.4,0.1c1.3,0.2,2.6,0.4,3.9,0.6c2.7,0.4,5.5,0.8,8.2,1.3c0.4,1,1.1,1.8,2.2,2.3c0.2,0.1,0.4,0.1,0.7,0.1c1.6,0,3.1,0,4.7,0
		c0.3,0.1,0.5,0.2,0.8,0.3h0h0c0.9,0,1.5-0.1,2.2-0.1c0.4,0,0.9,0,1.4-0.1l0.4,0L25,44.6c0.5,0,0.9,0,1.4,0c0.1,0,0.2,0.1,0.3,0.1h0
		h0c0.8,0,1.6-0.1,2.4-0.1c2.1,0,4.3,0,6.4,0c0.2,0,0.3,0,0.4-0.1c0.8-0.3,1.4-0.8,1.9-1.4c0.3,0.1,0.5,0.2,0.8,0.3h0
		c1.2,0,2.5,0.1,3.7,0.2l0.4,0l-0.3-0.2c-1.2-0.8-2.6-1.5-3.9-2.2c0-0.3,0-0.5,0-0.8c1.2,0.6,2.4,1.4,3.5,2.1
		c0.7,0.4,1.4,0.9,2.1,1.3h0c1.4,0.2,2.8,0.5,4.2,0.8l0.7,0.2l-0.6-0.3C47,43.6,45.4,42.5,43.9,41.3z M36.5,41.8
		c-0.1,0.2-0.2,0.4-0.4,0.5c-0.6-0.2-1.3-0.5-1.9-0.7c0.6-0.4,1.1-0.8,1.7-1.1c0.3,0.1,0.5,0.2,0.8,0.3
		C36.8,41.1,36.7,41.4,36.5,41.8z M22.5,42.7c-0.9-0.4-1.7-0.7-2.5-1c-0.5-0.4-0.9-0.7-1.3-1.1v0c-0.4-0.4-0.8-0.8-1.2-1.2
		c0.9,0.4,1.9,0.7,2.8,1.1c0.9,0.9,1.9,1.6,2.9,2.3C22.9,42.7,22.7,42.7,22.5,42.7z M16.2,42.7c-0.4,0-0.7-0.2-1-0.6
		c-0.3-0.4-0.5-0.8-0.5-1.2c0.3,0.1,0.6,0.2,0.9,0.3c0.6,0.5,1.3,1,2,1.5C17.2,42.7,16.7,42.7,16.2,42.7z M35,37.6
		c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.7,0-1,0l-1-0.3c-1.6-0.5-3.3-1-4.9-1.7v0l0.7-0.1c2.4,0.9,4.9,1.2,7.5,1L35,37.6z M26,35.9
		c1.7,0.7,3.5,1.3,5.2,1.8l0.3,0.1c-2.4-0.2-4.5-1.1-6.8-2.2h1.6l0,0l-0.6,0.1L26,35.9z M21.6,35.7c0.4,0.3,0.9,0.5,1.3,0.8
		c-0.2,0.1-0.5,0.1-0.7,0.2c-0.4-0.3-0.9-0.7-1.4-1H21.6z M22.8,35.7h0.5C23.2,35.7,23,35.7,22.8,35.7z M18.4,35.7h1
		c0.8,0.9,1.5,1.7,2.3,2.5L20,37.5h0C19.4,36.9,18.9,36.3,18.4,35.7z M18.4,38.6c-0.4-0.1-0.7-0.3-1-0.4v0c0.2,0,0.4-0.1,0.6-0.1
		C18.1,38.3,18.3,38.5,18.4,38.6z M23.8,41.1L23.8,41.1c1.4,0.6,2.9,1.1,4.4,1.6c-0.5,0-1,0-1.5,0C25.7,42.3,24.7,41.8,23.8,41.1z
		 M28.7,42.7c-1.9-0.6-3.8-1.4-5.6-2.1c-1-0.9-2-1.8-2.9-2.8c0.6,0.2,1.3,0.5,1.9,0.7c2.9,2.7,6.3,4.6,10.6,3.9
		c0.3,0.1,0.7,0.2,1.1,0.3C32.1,42.7,30.4,42.7,28.7,42.7z M25.8,38.9c1.3,0.4,2.5,0.8,3.8,1.3C28.2,39.9,26.9,39.5,25.8,38.9z
		 M30.6,40.3c-1.7-0.6-3.3-1.1-5-1.7l-0.8-0.3c-0.7-0.4-1.5-0.9-2.2-1.5c0.2-0.1,0.4-0.1,0.7-0.2v0c2.1,1.3,4.2,2.6,6.6,3
		c1.2,0.2,2.5,0.1,3.7-0.1c0.5,0.2,0.9,0.3,1.4,0.5C33.5,40.4,32,40.4,30.6,40.3z M35.3,40L35.3,40c-0.5-0.2-0.9-0.3-1.4-0.5
		c0.5-0.1,1.1-0.2,1.6-0.3c0.2,0.1,0.4,0.1,0.5,0.2l0.2,0.4C36,39.9,35.6,40,35.3,40z M22.5,25.3c0.1-0.2,0.2-0.4,0.2-0.6
		c0-2.7,0-5.4,0-8.1l0-1.8c0-0.2-0.2-0.4-0.4-0.4h-1.6c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		c0.1,0,0.1,0,0.2,0h8.8c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.1,0,0.1c0,0-0.1,0.1-0.2,0.1H28c-0.2,0-0.4,0.2-0.4,0.4l0,1.8
		c0,2.7,0,5.4,0,8.1c0,0.2,0.1,0.4,0.2,0.6l5.1,8.7H18.1L22.5,25.3z M16.8,36.6c0.4,0.5,0.7,0.9,1.1,1.4c-0.2,0-0.5,0.1-0.8,0.1
		l-0.4,0l0.4,0.1c0.6,0.2,1.2,0.5,1.8,0.7c0.3,0.4,0.7,0.7,1,1.1c-0.9-0.4-1.8-0.7-2.7-1.1c-0.3-0.4-0.6-0.8-0.9-1.1L16.8,36.6z
		 M10.7,38.5c0.7,0,1.3,0,1.9,0h0.1c0.2,0.2,0.4,0.5,0.7,0.7l-0.1,0.1C12.5,39,11.6,38.7,10.7,38.5L10.7,38.5z M14.8,40.6
		C14.8,40.6,14.8,40.6,14.8,40.6l0.4,0.3c-0.1,0-0.3-0.1-0.4-0.1C14.8,40.7,14.8,40.6,14.8,40.6z M4.9,40.7
		c-1.1-0.2-2.3-0.3-3.5-0.5c2-0.6,4-1.1,6.1-1.5c1.7,0.5,3.6,1.1,5.4,1.7c-0.1,0.6-0.1,1.1,0.1,1.6C10.3,41.4,7.6,41.1,4.9,40.7z
		 M21.4,42.7c-0.3-0.2-0.6-0.4-0.9-0.6c0.5,0.2,1,0.4,1.5,0.6C21.8,42.7,21.6,42.7,21.4,42.7z M35.6,42.7c-0.1,0-0.2,0.1-0.3,0.1
		c-0.2,0-0.4,0-0.5,0c-0.5-0.1-1.1-0.3-1.6-0.4c0.3-0.2,0.6-0.4,0.9-0.6c0.6,0.2,1.3,0.5,1.9,0.7C35.8,42.5,35.7,42.6,35.6,42.7z
		 M42.1,43.5c-1.2-0.1-1.9-0.1-3.3-0.1c-0.3-0.1-0.5-0.2-0.8-0.3c0.3-0.4,0.5-0.9,0.6-1.5C39.8,42.2,40.9,42.8,42.1,43.5L42.1,43.5z
		 M36.1,40.3c0.1-0.1,0.2-0.1,0.3-0.2l0.1,0.2c0,0.1,0.1,0.1,0.1,0.2C36.5,40.4,36.3,40.4,36.1,40.3z M44.3,43.8L44.3,43.8
		c-0.7-0.4-1.4-0.9-2.1-1.3c-1.2-0.8-2.4-1.5-3.6-2.1c0-0.2-0.1-0.4-0.2-0.6l-0.6-1c0.5-0.1,1-0.2,1.4-0.3c0.7,0.4,1.4,0.8,2.1,1.2
		c0.9,0.6,1.7,1.3,2.6,1.9c1.3,1,2.7,2,4.1,2.9C46.7,44.2,45.5,43.9,44.3,43.8z"
        />
        <circle className="st0" cx="24" cy="9.6" r="1.1" />
        <circle className="st0" cx="27.1" cy="6.1" r="1.1" />
        <circle className="st0" cx="27.4" cy="10.1" r="1.1" />
      </g>
    </SvgIcon>
  );
};

export default Icon;
