import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M23.5,0.5c0.7,0.3,1.1,0.8,1.1,1.7c0,0.2,0.2,0.4,0.4,0.5c2.6,2,5.2,3.9,7.8,5.9c0.4,0.3,0.6,0.7,0.9,1
				c0.1,0.1,0.1,0.2,0.2,0.2c0.6,0.1,0.7,0.5,0.7,1c0,1.9,0,3.8,0,5.7c0,0.4,0.1,0.6,0.4,0.8c1.6,1.2,3.2,2.4,4.7,3.6
				c0.3,0.3,0.6,0.6,0.9,1c0.1,0.1,0.2,0.2,0.3,0.2c0.4,0.1,0.6,0.4,0.6,0.8c0,0.1,0,0.3,0,0.4c0,8.1,0,16.1,0,24.2
				c0,0.2,0,0.4,0,0.7c0.4,0,0.8,0,1.2,0c0.7,0,1.1,0.2,1.1,0.7c0,0.5-0.3,0.7-1.1,0.7c-3.7,0-7.3,0-11,0c-8.1,0-16.2,0-24.3,0
				c-0.8,0-1.1-0.2-1.1-0.7c0-0.5,0.4-0.7,1.1-0.7c0.4,0,0.7,0,1.2,0c0-0.2,0-0.4,0-0.6c0-12.2,0-24.3,0-36.5c0-0.6,0-1.1,0.7-1.2
				c0,0,0.1-0.1,0.1-0.1c0.4-0.4,0.7-0.9,1.2-1.2c2.5-1.9,5-3.8,7.5-5.7c0.3-0.2,0.4-0.4,0.4-0.7c0-0.7,0.5-1.2,1.1-1.5
				C20.9,0.5,22.2,0.5,23.5,0.5z M18.2,21.9c0,0,0,0.1,0,0.1c0.2,0,0.4,0,0.6,0c4.6,0,9.2,0,13.8,0c0.7,0,1.1,0.2,1.1,0.7
				c0,0.5-0.3,0.7-1.1,0.7c-2.5,0-5,0-7.5,0c-0.2,0-0.4,0-0.5,0c0,8.2,0,16.4,0,24.6c5.2,0,10.3,0,15.4,0c0-8.2,0-16.4,0-24.6
				c-0.5,0-0.9,0-1.3,0c-0.9,0-1.7,0-2.6,0c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.2-0.7,0.6-0.7c0.2,0,0.4,0,0.7,0c0.6,0,1.3,0,2.1,0
				c-0.2-0.2-0.4-0.3-0.5-0.4c-2.5-1.9-5-3.8-7.5-5.6c-0.5-0.4-0.8-0.8-0.8-1.4c0-0.1,0-0.2-0.1-0.3c-1,0-2.1,0-3.1,0
				c0.2,1.1-0.5,1.6-1.3,2.1c-0.7,0.5-1.4,1-2,1.5C21.8,19.2,20,20.5,18.2,21.9z M19.9,2c0.2,1.1-0.6,1.6-1.3,2.2
				c-2.3,1.7-4.6,3.5-7,5.2c-0.1,0.1-0.2,0.2-0.5,0.4c0.3,0,0.5,0,0.7,0c4.4,0,8.7,0,13.1,0c0.2,0,0.4,0,0.6,0.1
				c0.4,0.1,0.5,0.4,0.5,0.7c0,0.3-0.2,0.6-0.6,0.6c-0.2,0-0.3,0-0.5,0c-4.8,0-9.6,0-14.4,0c-0.2,0-0.4,0-0.6,0
				c0,12.3,0,24.6,0,36.9c1.8,0,3.6,0,5.4,0c0-0.2,0-0.4,0-0.6c0-8.1,0-16.1,0-24.2c0-0.1,0-0.3,0-0.4c0-0.4,0.2-0.7,0.6-0.8
				c0.1,0,0.2-0.1,0.3-0.2c0.3-0.3,0.5-0.7,0.8-0.9c2.6-2,5.3-4,7.9-5.9c0.2-0.2,0.4-0.3,0.4-0.7c0-0.9,0.6-1.5,1.6-1.5
				c0.9,0,1.9,0,2.8,0c1,0,1.6,0.6,1.6,1.6c0,0.1,0,0.3,0.1,0.4c0.5,0.4,1,0.7,1.5,1.1c0-1.6,0-3.2,0-4.7c-1.5,0-3,0-4.4,0
				c-0.6,0-0.9-0.3-0.9-0.7c0-0.4,0.3-0.7,0.9-0.7c0.9,0,1.8,0,2.7,0c0.1,0,0.3,0,0.5,0c-0.2-0.2-0.3-0.3-0.5-0.4
				c-2.5-1.9-5-3.8-7.5-5.6c-0.5-0.3-0.7-0.8-0.8-1.4c0-0.1,0-0.2,0-0.4C22.1,2,21.1,2,19.9,2z M18.5,48.1c0-8.2,0-16.4,0-24.6
				c-0.5,0-1,0-1.6,0c0,8.2,0,16.4,0,24.6C17.5,48.1,18,48.1,18.5,48.1z M23.1,44.9c-1.1,0-2.1,0-3.1,0c0,1.1,0,2.1,0,3.1
				c1.1,0,2.1,0,3.1,0C23.1,47,23.1,46,23.1,44.9z M20,23.4c0,0.6,0,1.1,0,1.6c1.1,0,2.1,0,3.1,0c0-0.5,0-1,0-1.6
				C22.1,23.4,21,23.4,20,23.4z M23.1,40.4c0-0.6,0-1.1,0-1.6c-1.1,0-2.1,0-3.1,0c0,0.5,0,1,0,1.6C21,40.4,22,40.4,23.1,40.4z
				 M23.1,43.4c0-0.6,0-1.1,0-1.6c-1,0-2.1,0-3.1,0c0,0.5,0,1.1,0,1.6C21,43.4,22,43.4,23.1,43.4z M23.1,26.6
				c-0.1,0-0.1-0.1-0.1-0.1c-0.6,0-1.2,0-1.8,0c-1.3,0-1.3,0-1.2,1.3c0,0.2,0.1,0.3,0.3,0.3c0.5,0,1,0,1.4,0
				C23.2,28.1,23.2,28.1,23.1,26.6z M23.1,29.6c-1.1,0-2.1,0-3.1,0c0,0.5,0,1,0,1.6c0.6,0,1.2,0,1.7,0
				C23.2,31.2,23.2,31.2,23.1,29.6z M23.1,34.2c0-0.5,0-1,0-1.6c-1.1,0-2.1,0-3.1,0c0,0.5,0,1,0,1.6C21,34.2,22,34.2,23.1,34.2z
				 M23.1,35.7c-1.1,0-2.1,0-3.1,0c0,0.5,0,1,0,1.6c1.1,0,2.1,0,3.1,0C23.1,36.8,23.1,36.3,23.1,35.7z"
        />
        <path
          className="st0"
          d="M36.9,34.2c0-1.2,0-2.4,0-3.6c0-0.6,0.3-0.9,0.7-0.9c0.5,0,0.7,0.3,0.7,0.9c0,2.4,0,4.9,0,7.3
				c0,0.6-0.3,0.9-0.7,0.9c-0.4,0-0.7-0.3-0.7-0.9C36.9,36.6,36.9,35.4,36.9,34.2z"
        />
        <path
          className="st0"
          d="M37,26.5c0-0.3,0-0.5,0-0.8c0-0.4,0.3-0.6,0.6-0.6c0.4,0,0.7,0.1,0.7,0.5c0.1,0.6,0.1,1.3,0,1.9
				c0,0.4-0.3,0.5-0.7,0.5c-0.4,0-0.6-0.3-0.6-0.6C36.9,27.1,37,26.8,37,26.5C37,26.5,37,26.5,37,26.5z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
