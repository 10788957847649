import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M49.4,41.8C49.4,41.8,49.4,41.8,49.4,41.8c-0.2-0.4-0.5-0.6-1-0.6l-3.9,0c0.2-0.3,0.3-0.6,0.4-0.9
	c0.3,0,0.7,0,1,0l0.4,0c0.9,0,1.3-0.4,1.3-1.2l0-8c0-0.1,0-0.1,0-0.1l0-1l0-0.1c0-0.1,0-0.3,0-0.5l0-0.2c0-0.1,0-0.3,0-0.4
	c-0.5-2.6-2.1-4.3-4.5-5.1c0-0.8,0-1.7,0-2.5c0-0.9,0-1.9,0-2.8c0-0.5-0.2-0.9-0.7-1.2c-1-0.6-2-1.1-3-1.7L26.4,8.3
	c-0.1-0.1-0.2-0.1-0.3-0.1l-0.5-0.3c0,0-0.1-0.1-0.1-0.1l-0.7-0.4c-0.2-0.1-0.3-0.2-0.5-0.3c-0.5-0.2-1.2-0.4-2.1,0.1
	c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-7.7,4.3c-3.4,1.9-6.7,3.7-10.1,5.6c-0.5,0.3-0.7,0.7-0.7,1.2c0,2.5,0,5,0,7.6l0,2
	c0,0,0,0.1,0,0.1l0,1.7c0,0.1,0,0.2,0,0.2v1.2c0,0,0,0.1,0,0.1l0,9.8H2.9c-0.5,0-0.9,0-1.4,0c-0.3,0-0.5,0.1-0.7,0.3
	c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.5,0.4,0.9,1,1l2,0c2.7,0,5.5,0,8.2,0c0,0,0.1,0,0.1,0h3c0,0,2.8,0,3,0l0.4,0c0.5,0,0.9,0,1.4,0l1.9,0
	c2.7,0,5.4,0,8.1,0c0,0,0.1,0,0.1,0l1.4,0h0c0,0,0.1,0,0.1,0l4.1,0c0.1,0,0.2,0,0.2,0l1.4,0c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0
	l10.4,0c0.4,0,0.8-0.2,1-0.5C49.5,42.4,49.5,42.1,49.4,41.8z M34.3,41.2l-3,0c0.2-0.3,0.3-0.6,0.4-0.9h2.6L34.3,41.2z M18.5,40.1
	c-0.1-0.1-0.3-0.5-0.2-1.3c0.1-0.9,1-1.6,2-1.6c0,0,0,0,0.1,0c1,0,1.8,0.8,1.9,1.8c0.1,0.8-0.2,1.4-0.8,1.9
	c-0.6,0.4-1.3,0.5-2.1,0.2c-0.2-0.1-0.3-0.2-0.5-0.4C18.7,40.5,18.6,40.3,18.5,40.1z M16.5,40.6c0,0,0.1,0.3,0.3,0.6h-0.6
	c-1.2,0-2.5,0-3.7,0c0-6,0-12.1,0-18.1v-3.8c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0c0.3,0,1,0,1.2,0c0,0,16.5,0,16.6,0c0,0,0,0,0,0
	c0.1,0,2.8,0,2.8,0l0.1,0c0.3,0,0.6,0,0.9,0l0,0.2c0,0,0,0.1,0,0.1l0,0.8c-2,0-11.4,0-14.3,0c-0.5,0-3.8,0-3.8,0c-0.1,0-0.3,0-0.3,0
	l-0.4,0c-0.2,0-0.5,0-0.8,0.1c-0.4,0.1-0.8,0.3-0.8,1l0,14.2c0,0.8,0.4,1.2,1.2,1.2h1.8C16.2,38.2,16.1,39.4,16.5,40.6z M10.4,18.5
	c0,3.7,0,7.4,0,11.1c0,0,0,0.1,0,0.1v1.8c0,0,0,0.1,0,0.1l0,9.5H5.3l0-12.9l0-9.4c3.3-1.8,6.6-3.7,9.9-5.5l8.1-4.5c0,0,0,0,0.1,0
	l0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.2l0.7,0.4c0.1,0.1,0.2,0.1,0.2,0.1l5.8,3.3c3.5,2,6.9,3.9,10.4,5.9c0,1.2,0,2.3,0,3.5l0,1.2l-0.5,0
	c-0.7,0-1.5,0-2.2,0c0-0.3,0-0.7,0-1l0-0.8c0-0.4-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.9-0.3l0,0.6h0l-0.2-0.6l-0.2,0
	c-0.1,0-0.2,0-0.3,0c0-0.4,0-1,0-1.4c0-0.4,0-0.6,0-0.6c0-0.9-0.6-1.1-1.1-1.1H11.6C10.8,17.3,10.4,17.7,10.4,18.5z M24.1,40.5
	c0.1,0.2,0.2,0.4,0.3,0.7h-0.6C23.9,40.9,24,40.7,24.1,40.5z M23.7,37.1h0.6c-0.1,0.2-0.2,0.4-0.3,0.6C24,37.5,23.9,37.3,23.7,37.1z
	 M15.8,35.1V22.5h4.4c5.3,0,10.6,0,15.9,0c0,4.2,0,8.4,0,12.6c-2.4,0-4.9,0-7.3,0H15.8z M41,37.1C41,37.1,41,37.1,41,37.1
	c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4c0,1.1-0.9,2-2,2h0h0c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.5
	C39,38,39.9,37.1,41,37.1z M44.7,37.1c0.3,0,0.6,0,0.9,0c0,0.4,0,0.8,0,1.2c-0.2,0-0.4,0-0.6,0c-0.1-0.4-0.2-0.8-0.5-1.2H44.7z
	 M45.5,38.6C45.5,38.6,45.5,38.6,45.5,38.6l0.6,0L45.5,38.6z M42,35.1c-1.3,0-2.6,0-3.8,0c0-3.2,0-6.4,0-9.7c0.3,0,0.6,0,1,0
	c0.7,0,1.3,0,2,0c2,0,3.6,1.3,4.2,3.2c0.1,0.2,0.1,0.4,0.2,0.7l0,3.5c0,0.8,0,1.5,0,2.3C44.4,35.1,43.2,35.1,42,35.1z M26.6,40.7
	c-0.7-0.5-0.9-1.2-0.7-2.1c0.2-0.8,0.7-1.3,1.6-1.5c0.1,0,0.3,0,0.4,0c0.5,0,1,0.2,1.4,0.6c0.5,0.5,0.7,1.2,0.5,1.9l-0.1,0.2
	c0,0.1,0,0.1,0,0.2l-0.1,0.3l-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.1-0.3,0.2-0.5,0.3C28,41.3,27.3,41.3,26.6,40.7z M31.3,37.1
	h6.3c-0.2,0.4-0.4,0.8-0.4,1.2l-1.9,0c-1.1,0-2.3,0-3.4,0C31.6,37.9,31.5,37.5,31.3,37.1z M36.6,40.3c0.1,0,0.1,0,0.2,0l0.3,0
	c0.1,0,0.1,0,0.1,0l0.2,0.4c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3h-1.3l0-0.8H36.6z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
