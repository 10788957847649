import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M45.4,5.1c-0.5-0.4-1-0.2-1.5-0.1c0,0-0.1-0.1-0.1-0.1c-0.3-0.2-0.5-0.4-0.7-0.6c-2.9-3.1-6.3-4.1-10.1-3.1
		c-2.7,0.7-4.7,2.2-6.1,4.6c-3.3-3.5-8.6-4.9-13.2-3.3C12,3,10.5,3.9,9.1,4.7C8.4,5.2,7.7,5.6,7,6c-2.7,1.4-4.7,4.2-5.8,7.7
		C0,17.8,0.3,22,2.1,25.1c0.7,1.2,1.8,2.6,4.2,1.3c4.7-2.5,7.7-6.5,8.6-11.6c0.4-2,0.3-4,0.2-6c0-0.7-0.1-1.3-0.1-2
		c2.6-0.3,4.9,0.4,6.6,1.8c1.9,1.6,2.9,4.1,2.9,7c0,2.8,0,5.6,0,8.4c0,2,0,4.1,0,6.1c0,0.4,0,0.8,0.1,1.4c0,0.2,0,0.5,0.1,0.8
		c-0.7,0.1-1.4,0.4-2,1c-1.7-1.3-4.7-1.6-6.1-0.5c-0.5,0.4-0.7,1-0.9,1.5c0,0.1-0.1,0.2-0.1,0.3c0-0.1,0-0.1,0-0.2
		c0.1-0.9-0.3-1.4-0.6-1.7c-0.9-0.8-2.3-0.6-3.5-0.5c-0.9,0.1-1.9,1.2-1.9,2.1c-0.1,4.4-0.1,8.7,0,12.9c0,0.5,0.4,1.9,1.3,1.9
		c1.5,0,2.9,0.1,4.4,0.1c0.5,0,1,0,1.5,0l1.5,0c0.4,0,0.8-0.3,0.8-0.8l0-0.8c0-0.4-0.3-0.8-0.8-0.8c-0.5,0-1,0-1.6,0
		c-1.2,0-2.4,0-3.7,0c-0.5,0-0.8,0-1-0.1c-0.1-0.1-0.2-0.4-0.2-1.1c0.1-2.2,0.1-4.3,0-6.4c0-1,0-2.1,0-3.1c0-0.1,0-0.3,0-0.4
		c0-0.3,0-0.8,0.1-0.9c0,0,0.2-0.1,0.7-0.1c0.4,0,0.6,0.1,0.7,0.2c0.1,0.2,0.1,0.6-0.1,1.2c-0.6,2.3,0.2,4.1,1,5.7
		c0.4,0.8,0.8,1.5,1,2.3c0,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.1,0.6,0.1l1-0.3c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.1-0.5,0-0.7
		c-1.4-2.5-2.1-5-2.1-7.7c0.1,0.1,0.2,0.2,0.3,0.3l0.6,0.4c0.2,0.1,0.5,0.1,0.7,0s0.4-0.3,0.4-0.5c0.2-1.2,0.7-1.2,1.9-1
		c0.3,0,0.6,0.1,0.9,0.1c0.2,0.1,0.4,0.4,0.5,0.5l0,0.2c-0.1,1.7-0.2,3.2-1.6,4.2c-0.4,0.3-0.8,0.7-1.1,1c-0.1,0.1-0.2,0.3-0.4,0.4
		c-0.2,0.2-0.3,0.6-0.1,0.9c0.2,0.3,0.5,0.4,0.8,0.4c2.5-0.6,4-2.2,4.6-4.9c0.2-0.8,0.6-1.5,1-1.9c1.8-1.5,5.7-1.3,7.5,0.4
		c0.5,0.5,1.4,1.1,2.2,1c2-0.2,3,0.4,3.4,2.1c0.1,0.4,0.5,0.6,1.2,0.6c0.4,0,0.7-0.4,0.7-0.8c-0.2-3.2-0.6-3.7-3.4-4.2l0.4,0
		c1.2-0.1,2.5-0.1,3.7,0.1c0.5,0.1,1.2,0.5,1.8,1.2c-0.2,0.2-0.4,0.6-0.5,0.8c-0.2,0.2-0.3,0.5-0.5,0.7c-0.2,0.3-0.4,0.6-0.6,0.9
		l-0.3,0.4c-0.2,0.3-0.2,0.7,0,0.9c0.2,0.3,0.6,0.3,0.9,0.2c3-1.5,4.1-2.5,3.5-5.1c0.1,0,0.1,0,0.1,0c0,0,0.1,0.2,0.1,0.6
		c0,1.9,0,3.9,0,5.8c0,0.3,0.1,0.6,0.1,0.9c-0.2-0.1-0.5-0.3-0.7-0.3c-1.2-0.2-2.5-0.3-3.7-0.4c-0.6,0-1.1-0.1-1.7-0.1
		c-0.3,0-0.6,0.2-0.8,0.5c-0.1,0.3,0,0.7,0.2,0.9c1.4,1.1,2.8,2.1,4.9,1.7c0.9-0.2,1.1,0.2,1.5,1.6l0.1,0.2c0,0.2-0.2,0.6-0.3,0.8
		c-2.3,0.1-4.6,0.1-7,0.1l-1.2,0c0.2-1.4-0.3-2.6-0.7-3.7c-0.7-1.5-1-2.5-0.1-3.7c0.2-0.3,0.2-0.7,0-0.9c-0.2-0.3-0.6-0.3-0.9-0.2
		c-0.8,0.4-2.6,1.4-1.8,3.8c0.4,1.3,0.8,2.7,1.1,4.2c0.2,0.7,0.3,1.5,0.5,2.2c0.1,0.3,0.4,0.6,0.7,0.6h1.8c0.7,0,1.3,0,2,0
		c2.2,0,4.5,0,6.8,0c0.5,0,1-0.3,1.3-0.7c0.7-0.9,0.4-2.7-0.1-4.1c-0.1-0.3-0.3-0.8-0.7-1.3l0.4-0.1c0.4,0,0.7-0.4,0.7-0.8l0-2
		c0-2.1,0-4.2-0.1-6.3c-0.1-1-1.6-1.8-2.4-1.8c-1.5-0.1-3-0.1-4.4-0.1c-1.2,0-2.3,0-3.5,0l-0.2,0c-1.2,0-2.7-0.1-3.4,1.2L30.1,32
		c0-1.6,0-3.2,0-4.7c0-4.4,0-8.9-0.1-13.4l0-0.4c-0.1-1.9-0.1-3.5,1-5.1C32.7,6,35,5,37.5,5.4c-0.1,0.4-0.1,0.7-0.2,1.1
		c-0.9,5.4,2.1,11.7,6.9,14.4c0.6,0.3,1.6,0.8,2.5,0.5c0.9-0.3,1.5-1.2,1.8-1.8C50.7,15.1,49.2,8,45.4,5.1z M28.3,7.6
		c-0.1-0.2-0.2-0.4-0.3-0.5c0.5-0.4,1-0.7,1.5-1.1c0.5-0.4,1.1-0.8,1.6-1.2c-0.8,0.7-1.5,1.7-2.3,2.9c-0.1,0.1-0.2,0.2-0.2,0.3
		C28.4,7.8,28.3,7.7,28.3,7.6z M26.6,13c-0.2-2.6-1.6-5-3.8-6.7c-1.8-1.3-4-2.1-6.3-2.1c2.8-0.3,5.6,0.4,7.7,2.1
		c2,1.6,3.2,4,3.4,6.6c0.3,4.6,0.2,9.2,0.2,13.8c0,1.8-0.1,3.7-0.1,5.5c-0.4,0.1-0.8,0-1.2,0c0.2-0.3,0.4-0.5,0.4-0.8
		c0-1.8,0-3.6,0-5.4C26.9,21.7,27,17.3,26.6,13z M5.7,15.8c0.1,0.3,0.4,0.6,0.7,0.6c0.3,0,0.7-0.2,0.8-0.5c2.6-7.5,2.9-8.4,4.7-9.6
		C12,6.5,12,6.8,12.1,7c0.4,1.1,0.7,2.1,0.8,3.1c0.5,5.3-2.8,11.6-7.3,13.9c-0.5,0.2-0.8,0.3-1,0.3c-0.2-0.1-0.4-0.4-0.7-0.9
		C1.8,19.3,3.1,13.1,6.3,9.6C5.6,11.5,5.2,13.4,5.7,15.8z M45.4,18.8c-2.1-0.9-4-3.1-5.1-6.1c-1-2.8-1.1-5.7,0-7.6c0,0,0,0,0,0
		c2.3,1.7,2.8,4.2,3.2,6.9c0,0.3,0.3,0.6,0.6,0.6c0.3,0.1,0.6-0.1,0.8-0.4c0.8-1.3,0.6-2.5,0.3-3.6c1.9,2.8,2.5,6.5,1.3,9.4
		C46.1,19,46,19,45.4,18.8z"
        />
        <path
          className="st0"
          d="M31,46.9h-8.6l-1.2-3.7c-0.1-0.2-0.2-0.4-0.4-0.4c-0.2-0.1-0.4-0.1-0.6,0l-0.4,0.2c-0.3,0.1-0.5,0.4-0.5,0.8
		l0.4,4.7c0,0.4,0.4,0.7,0.8,0.7h10.6c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.6l0-0.7C31.8,47.2,31.4,46.9,31,46.9z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
