import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M46.2,7.6c-0.3-0.5-0.9-1.1-2.4-1.1c-2.7-0.2-5.6-0.2-8.5,0c-1.2,0.1-2,0.4-2.3,1.1c-0.3,0.5-0.5,1.4,0.3,2.9
		c0.4,0.7,0.6,1.6,0.7,2.3c0,2,0,4.1,0,6.1c0,1.2,0,2.5,0,3.7c0,0.6,0.1,1.2,0.1,2l0.1,1c0,0.3,0.2,0.5,0.5,0.6
		c0.3,0.1,0.6,0,0.7-0.2l0.3-0.3c0.2-0.2,0.4-0.4,0.4-0.7c0-1.2,0-2.4,0-3.6c0-1.7,0-3.4,0-5.1c0-1,0-1.9,0-2.6c0-1.2,0-2,0.2-2.2
		c0.2-0.2,1.2-0.2,2.6-0.3l0.2,0c0.6,0,1.3,0,2.2,0c1,0,1.3,0.2,1.5,0.3c0.2,0.2,0.4,0.5,0.3,1.5c-0.1,4.2-0.1,8.5,0,12.6l0,0.5
		c0,1.6,0,3.1,0,4.7c0,1.1,0,2.2,0,3.2c0,2.7,0.1,5.5-0.2,8.2c-0.1,1.2-1.3,2.6-2.4,3.4c-0.3,0.2-1.1,0.1-1.9-0.1
		c-0.2,0-0.5-0.1-0.7-0.1c3.2-5.2,2.8-10-1.2-15c-1.4-1.8-2.5-3.3-3.2-4.8c-0.9-1.7-1.9-3.7-2-5.5c-0.3-4-0.2-8-0.1-12l0-1.5
		c0-0.5,0.4-1.1,0.8-1.6l0.1-0.2c0.8-1.1,1-2.2,0.6-3c-0.3-0.5-0.9-1.2-2.3-1.2c-3.4-0.2-7.1-0.2-11.3,0c-1.2,0.1-1.8,0.6-2,1
		c-0.3,0.4-0.6,1.2-0.1,2.5c0.6,1.7,1.2,3.7,1.5,5.6c1.1,9.1-1.1,16.5-6.6,22.8c-2.9,3.3-3.1,7.4-0.6,11.7c0.2,0.3,0.3,0.6,0.5,1.1
		c0,0.1,0.1,0.2,0.1,0.2c-0.3,0-0.5,0-0.8,0c-0.9,0-1.8,0.1-2.1-0.2c-1-0.9-2-2.5-2-3.8C7,33.8,7,25.9,7,18.1l0-5.4
		c0-0.3,0.1-0.7,0.2-1.2c0-0.1,0.1-0.2,0.1-0.4l1.4,0c1.3,0,2.6,0,3.9,0c0.5,0,0.9,0.1,1,0.3c0.2,0.2,0.3,0.6,0.3,1.2
		c-0.1,1.2,0,2.5,0,3.7l0,8.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2l0.9,0c0.4,0,0.7-0.3,0.7-0.7c0-0.9,0-1.8,0-2.6l0-0.2
		c0-2.3,0-4.7,0.1-7.1c0-0.8,0.2-1.7,0.5-2.5c0.2-0.9,0.5-1.9,0.5-2.8c0-1-1-3.1-1.9-3.1c-3-0.2-6.2-0.3-9.3,0c-1.2,0.1-1.7,0.6-2,1
		C3.5,8,3.3,8.9,3.9,10.2c0.6,1.2,0.8,2.2,0.8,3.1c0,5.5,0,11.1,0,16.5c0,3.8,0,7.7,0,11.6c0,2.4,0.7,4.3,2.1,5.4
		c1.4,1.1,3.3,1.4,5.7,0.8c0.6-0.2,1.5-0.1,2.3,0.2c3.5,1.2,7,1.8,10.3,1.8c3.4,0,6.8-0.6,10.1-1.9c0.6-0.2,1.3-0.3,1.8-0.2
		c2.8,0.7,4.8,0.5,6.1-0.6c1.3-1,2-2.9,2-5.8c0-4.3,0-8.7,0-12.9v-0.3c0-4.8,0-9.7,0-14.6c0-0.9,0.3-2,0.7-2.8
		C46.5,9.2,46.6,8.3,46.2,7.6z M16.5,29.8c4.2-5,4.6-11,4.3-16.2c-0.1-2-0.1-4,0-6.2l0-0.2c0-0.9,0-1.8,0-2.8c0-0.2-0.1-0.4-0.3-0.6
		l-1.1-0.7h11.2l0,0l-4.2,0.6c-0.3,0-0.5,0.3-0.6,0.6l0,0.2c0,0.3,0.2,0.7,0.5,0.7l2.7,0.5c0,0.8,0,1.5,0,2.3c0,2,0.1,4,0,6
		c-0.2,4.6,0.1,10.6,4.3,15.7c0.1,0.2,0.1,0.8,0.1,1.2c0,0.1,0,0.2,0,0.3c-0.2,0-0.3,0-0.5,0.1c-0.6,0.1-1.3,0.2-1.5,0
		c-1.9-1.3-3.4-1.3-5.2-0.1c-0.5,0.4-2,0.4-2.5,0c-0.9-0.6-1.7-0.9-2.5-0.9c-0.8,0-1.7,0.3-2.6,1c-0.3,0.2-1,0.1-1.5,0
		c-0.2,0-0.3,0-0.5-0.1c0-0.1,0-0.2,0-0.3C16.4,30.6,16.4,30,16.5,29.8z M31.3,46.7c-3.9,0.4-8.2,0.4-12.5,0c-3-0.2-5.3-2.2-6.4-5.5
		c-1-3.1-0.3-5.9,2.1-8.5c1.9,1.8,3.7,1.8,5.7,0.2c0.4-0.3,1.7-0.2,2.2,0.2c2.2,1.6,3.8,0.9,5.2-0.1c0.4-0.3,1.7-0.4,2-0.1
		c1.9,1.6,3.8,1.6,5.5,0c2.4,2,3.3,4.7,2.5,7.5C36.9,43.2,35.2,46.4,31.3,46.7z"
        />
        <circle className="st0" cx="15.8" cy="38.6" r="2.1" />
        <circle className="st0" cx="23.5" cy="43.6" r="2.6" />
        <circle className="st0" cx="29.3" cy="37.9" r="1.3" />
        <circle className="st0" cx="33.8" cy="40.5" r="1.8" />
      </g>
    </SvgIcon>
  );
};

export default Icon;
