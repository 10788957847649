import { RestService } from "./rest.service";
import _ from "lodash";

class AuthService extends RestService {
  loginWithGoogleToken(googleToken, verification_code = "", invite_code = "") {
    let options = !_.isEmpty(verification_code)
      ? {
          params: {
            verification_code
          }
        }
      : {};
    options = !_.isEmpty(invite_code)
      ? {
          ...options,
          params: {
            ...options.params,
            invite_code
          }
        }
      : options;
    const payload = { id_token: googleToken };
    return super.post("/auth/google/login", payload, options);
  }

  refreshToken() {
    const refresh_token = this.getRefreshToken();
    if (!refresh_token) {
      return new Error();
    }
    return super.post("/auth/refresh", { refresh_token });
  }

  logoutUser() {
    return super.post("/auth/logout");
  }

  getMe() {
    return super.get("/me");
  }

  getMeNotifications() {
    return super.get("/me/notifications");
  }

  putMeNotifications(data) {
    return super.put("/me/notifications", data);
  }

  updateMe(data) {
    return super.put("/me", data);
  }

  removeAvatar() {
    return super.destroy("/me/avatar");
  }

  setAvatar(data) {
    return super.post("/me/avatar", data);
  }

  getOrganisations() {
    return super.get("/organizations");
  }

  getJwt() {
    return localStorage.getItem("jwtToken");
  }

  deleteJwt() {
    localStorage.removeItem("jwtToken");
  }

  setJwt(token) {
    if (token) {
      localStorage.setItem("jwtToken", token);
    }
  }

  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  deleteRefreshToken() {
    localStorage.removeItem("refreshToken");
  }

  setRefreshToken(token) {
    if (token) {
      localStorage.setItem("refreshToken", token);
    }
  }

  getGoogleIdToken() {
    return localStorage.getItem("googleIdToken");
  }

  setGoogleIdToken(token) {
    if (token) {
      localStorage.setItem("googleIdToken", token);
    }
  }

  deleteGoogleIdToken() {
    localStorage.removeItem("googleIdToken");
  }
}

const authService = new AuthService();

export default authService;
