import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M31.8,8.8c-3.2-0.8-6.7-0.1-9.4,1.8c-2.1,1.4-3.6,3.5-4.4,6c-1.2-0.3-2.2-0.4-3.1-0.4c-2.6,0-4.6,1-7,3.2
		c-0.1,0.1-0.3,0.2-0.5,0.2c-3.1,0.4-5.7,2.8-6.1,5.8c-0.5,3.3,1.2,6.4,4.2,7.6c0.4,0.1,0.5,0.3,0.7,0.7c0.5,1.5,1.4,2.7,2.7,3.8
		c0.3,0.3,0.6,0.4,0.9,0.4c0.2,0,0.5-0.1,0.8-0.4c0.3-0.4,0.5-1.1-0.2-1.7c-1.1-1-1.9-2.1-2.2-3.5c-0.2-0.6-0.5-1-1.1-1.1
		c-2.2-0.7-3.7-2.8-3.5-4.9c0.3-2.5,2.2-4.3,4.6-4.4c0.6,0,1-0.2,1.4-0.7c1.4-1.7,3.5-2.7,5.6-2.7c2.4,0,4.7,1.2,6.1,3.3
		c0.3,0.5,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.9-0.2c1.3-0.5,2.7-0.2,3.6,0.7c1,1,1.2,2.4,0.5,3.7c-0.3,0.6-0.3,1.1,0.1,1.6
		c1.2,1.7,1.2,3.8,0.1,5.4c-1.1,1.7-3,2.4-5.1,2c-0.8-0.2-1.3,0.2-1.5,0.8c-0.2,0.7,0.2,1.2,1,1.4c0.5,0.1,1.1,0.2,1.6,0.2
		c3.1,0,6-2.2,6.8-5.2c0.1-0.3,0.2-0.7,0.2-1l0.1-0.4c3-0.4,5.5-1.8,7.4-4c1.7-2,2.7-4.5,2.8-7.1C40.9,15,37.2,10.1,31.8,8.8z
		 M30.4,29.4l-0.2-0.5c-0.2-0.4-0.4-0.8-0.5-1.2c-0.1-0.1-0.1-0.4,0-0.5c0.5-1.7,0.3-3.4-0.6-4.8c-0.9-1.4-2.3-2.3-4-2.5
		c-0.1,0-0.2,0-0.4,0c-0.2,0-0.5,0-0.8,0.1c-0.2,0-0.5,0.1-0.7,0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.6-0.6-1-0.9
		c-0.4-0.4-0.8-0.7-1.2-1.1c-0.2-0.2-0.3-0.3-0.2-0.6c1.3-3.5,3.6-5.6,7.1-6.3c0.6-0.1,1.3-0.2,1.9-0.2c4.6,0,8.5,3.4,9.2,8.1
		c0.4,2.4-0.3,4.9-1.8,6.9C35,27.8,32.8,29.1,30.4,29.4l0.1,0.6l0,0L30.4,29.4z"
        />
        <path
          className="st0"
          d="M19.1,43.6L19.1,43.6C19.1,43.6,19.1,43.6,19.1,43.6c1.2,0,1.2-1.2,1.2-1.6c0-3.3,0-6.3,0-9.3
		c0-0.3,0-0.5-0.1-0.7c-0.1-0.5-0.5-0.9-1.1-0.9c0,0-0.2,0-0.2,0c-0.6,0.1-0.9,0.6-0.9,1.2c0,1.2,0,2.4,0,3.5l0,2.8l0,3.3
		C18,42.4,18,43.6,19.1,43.6z"
        />
        <path
          className="st0"
          d="M15.5,46.6c0.2,0.1,0.3,0.1,0.5,0.1c0.6,0,1.1-0.5,1.1-1.3c0-1.1,0-2.2,0-3.3l0-3c0-1.1,0-2.3,0-3.5
		c0-0.8-0.4-1.3-1-1.3c0,0-0.1,0-0.1,0c-0.7,0-1.1,0.5-1.1,1.3c0,1.2,0,2.4,0,3.5l0,3.2l0,3.2C14.8,46,15,46.4,15.5,46.6z"
        />
        <path
          className="st0"
          d="M11.6,39.7c0,0.8,0.4,1.3,1.1,1.3c0.7,0,1.1-0.5,1.1-1.2c0-3.2,0-6.6,0-10c0-0.7-0.4-1.2-1.1-1.2h0
		c-0.7,0-1.1,0.4-1.1,1.2l0,3.6l0,1.3l0,1.6L11.6,39.7z"
        />
        <path
          className="st0"
          d="M48.5,19.3c-0.1-0.1-0.4-0.3-0.8-0.3c-0.5,0-1.1,0-1.6,0c-0.2-0.3-0.8-0.3-1,0c-0.5,0-1,0-1.4,0h0
		c-0.4,0-0.7,0.2-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.6,0.5,1,1.2,1.1c0.2,0,0.5,0,0.7,0l1.2,0l1.2,0c0.3,0,0.6,0,0.8,0
		c0.7,0,1.2-0.4,1.2-1.1C48.8,19.9,48.7,19.6,48.5,19.3z"
        />
        <path
          className="st0"
          d="M29.1,6.8C29.1,6.8,29.2,6.8,29.1,6.8c0.7,0,1.1-0.5,1.1-1.2c0-0.5,0-0.9,0-1.4l0-0.6l0-0.6c0-0.5,0-0.9,0-1.4
		c0-0.7-0.5-1.1-1.1-1.2C28.5,0.5,28,1,28,1.7c0,1.3,0,2.7,0,4C28,6.3,28.5,6.8,29.1,6.8z M29.1,6.2L29.1,6.2L29.1,6.2L29.1,6.2z"
        />
        <path
          className="st0"
          d="M40.1,29.6c-0.5-0.4-1.2-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6c1,1.1,2,2,2.9,2.8c0.1,0.1,0.3,0.2,0.5,0.3
		c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3-0.1,0.4-0.1c0.2-0.2,0.4-0.3,0.6-0.5c0,0,0,0,0,0c0.2-0.3,0.3-0.8-0.2-1.3
		C42,31.3,41.1,30.4,40.1,29.6z"
        />
        <path
          className="st0"
          d="M18.1,10.7c0.1,0.1,0.3,0.2,0.6,0.3c0.1,0,0.2,0,0.2,0c0.2,0,0.3,0,0.4-0.1c0.2-0.2,0.4-0.3,0.6-0.5
		c0.2-0.3,0.3-0.8-0.2-1.3c-0.8-0.8-1.8-1.8-2.9-2.8c-0.5-0.4-1.2-0.4-1.6,0c-0.2,0.2-0.4,0.5-0.3,0.9c0,0.3,0.1,0.6,0.4,0.8
		C16.4,9,17.3,9.9,18.1,10.7z"
        />
        <path
          className="st0"
          d="M39,11c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1-0.1,0.1-0.1c1-1,2.1-2,3-3
		c0.2-0.2,0.3-0.5,0.3-0.9c0-0.2-0.2-0.5-0.4-0.7c-0.4-0.4-1.1-0.4-1.5-0.1c-0.9,0.9-1.9,1.9-3.1,3c-0.3,0.4-0.4,0.9-0.1,1.3
		C38.5,10.7,38.8,10.8,39,11z"
        />
        <path
          className="st0"
          d="M12.7,41.9L12.7,41.9C12.7,41.9,12.7,41.9,12.7,41.9c-0.6,0-1.1,0.5-1.1,1.2c0,0.3,0.1,0.6,0.3,0.9
		c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.3-0.8C13.8,42.5,13.3,41.9,12.7,41.9z"
        />
        <path
          className="st0"
          d="M19.1,44.5C19.1,44.5,19.1,44.5,19.1,44.5c-0.7,0-1.2,0.5-1.2,1.1c0,0.3,0.1,0.6,0.3,0.8
		c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.3-0.5,0.3-0.8C20.3,45,19.8,44.5,19.1,44.5z"
        />
        <path
          className="st0"
          d="M15.9,47.2C15.9,47.2,15.9,47.2,15.9,47.2c-0.7,0-1.2,0.5-1.2,1.1c0,0.3,0.1,0.6,0.3,0.8
		c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.3-0.5,0.3-0.8C17.1,47.7,16.6,47.2,15.9,47.2z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
