import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M46,34.2c-0.7,0-1.3,0-2.1,0c0-0.7,0-1.3,0-2c0-1.1-0.5-1.9-1.5-2.5c-0.6-0.4-1.3-0.5-2-0.5
	c-4.6,0-9.2,0-13.8,0c-0.2,0-0.4,0-0.7,0c0-3.3,0-6.6,0-9.9c5-0.8,7.3-5,7-8.7c-0.4-4.3-3.7-7.4-7.9-7.4C21,3.1,17.5,6.1,17,10.3
	c-0.5,4.1,2.2,8.2,7,9c0,3.3,0,6.5,0,9.9c-0.2,0-0.5,0-0.7,0c-4.6,0-9.3,0-13.9,0c-2.1,0-3.4,1.3-3.4,3.4c0,0.5,0,1,0,1.6
	c-0.8,0-1.6,0-2.3,0c-1.9,0-3.2,1.3-3.2,3.2c0,2.1,0,4.1,0,6.2c0,2,1.3,3.3,3.3,3.3c2.1,0,4.2,0,6.2,0c2,0,3.3-1.2,3.3-3.3
	c0-2,0-4.1,0-6.1c0-0.3,0-0.7-0.1-1c-0.3-1.4-1.5-2.3-2.9-2.3c-0.8,0-1.5,0-2.3,0c0-0.6,0-1,0-1.5c0-1.1,0.4-1.4,1.4-1.4
	c4.7,0,9.3,0,14,0c0.2,0,0.4,0,0.5,0c0,1,0,2,0,2.9c-0.7,0-1.5,0-2.2,0c-1.9,0-3.2,1.3-3.2,3.2c0,2.1,0,4.1,0,6.2
	c0,2,1.3,3.2,3.3,3.3c2.1,0,4.2,0,6.3,0c2,0,3.3-1.3,3.3-3.3c0-2,0-4,0-6.1c0-2-1.3-3.3-3.3-3.3c-0.7,0-1.4,0-2.1,0c0-1,0-2,0-2.9
	c0.2,0,0.3,0,0.4,0c4.7,0,9.5,0,14.2,0c0.2,0,0.3,0,0.5,0.1c0.4,0.1,0.7,0.4,0.8,0.8c0.1,0.7,0,1.4,0,2.1c-0.7,0-1.4,0-2,0
	c-2,0-3.2,1.3-3.3,3.3c0,2,0,4,0,6.1c0,2,1.3,3.3,3.3,3.3c2.1,0,4.2,0,6.3,0c2,0,3.3-1.3,3.3-3.3c0-2,0-3.9,0-5.9
	C49.5,35.4,48.3,34.2,46,34.2z M9,38.2c0.5-0.4,1-0.4,1.3,0.1c0.2,0.4,0.2,0.7-0.1,1c-1.1,1.1-2.3,2.3-3.5,3.4
	c-0.4,0.4-0.8,0.3-1.2,0c-0.6-0.6-1.2-1.2-1.9-1.8c-0.4-0.4-0.4-0.8-0.1-1.2c0.3-0.4,0.8-0.4,1.2,0c0.4,0.4,0.8,0.9,1.2,1.4
	C7.1,40.1,8.1,39.2,9,38.2z M18.9,11.2c0-3.3,2.7-6,6-6c3.4,0,6.1,2.7,6,6.1c0,3.3-2.8,6-6.1,6C21.6,17.3,18.9,14.6,18.9,11.2z
	 M27.1,38.2c0.3-0.3,0.7-0.4,1.1-0.2c0.3,0.2,0.4,0.5,0.3,0.9c-0.1,0.2-0.2,0.3-0.3,0.5c-1.1,1.1-2.2,2.1-3.2,3.2
	c-0.5,0.5-0.9,0.5-1.4,0c-0.6-0.5-1.1-1.1-1.7-1.6c-0.4-0.4-0.5-0.9-0.1-1.3c0.4-0.3,0.8-0.3,1.2,0.1c0.4,0.4,0.8,0.9,1.2,1.3
	c0.1,0,0.2,0.1,0.3,0.1C25.3,40.2,26.2,39.2,27.1,38.2z M46.4,39.3c-0.2,0.2-0.4,0.4-0.6,0.6c-0.9,0.9-1.8,1.8-2.7,2.7
	c-0.5,0.5-0.9,0.5-1.4,0c-0.6-0.6-1.1-1.1-1.7-1.7c-0.4-0.4-0.4-0.9-0.1-1.2c0.3-0.3,0.8-0.3,1.2,0.1c0.4,0.4,0.8,0.9,1.2,1.3
	c1-0.9,1.8-1.8,2.7-2.6c0.1-0.1,0.2-0.2,0.4-0.4c0.4-0.3,0.8-0.3,1.1,0C46.7,38.5,46.7,39,46.4,39.3z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
