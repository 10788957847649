import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const ConfirmDialog = (props) => {
  const {
    title,
    isOpen,
    setIsOpen,
    onNoClick,
    onYesClick,
    showActions,
    description,
    children,
  } = props;

  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleYesClick = (ev) => {
    onYesClick(ev);
    setIsOpen(false);
  };

  const handleNoClick = (ev) => {
    onNoClick(ev);
    setIsOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
          {children}
        </DialogContent>
        {showActions && <DialogActions>
          <Button onClick={handleNoClick} color="primary">
            {t('no')}
          </Button>
          <Button onClick={handleYesClick} color="primary" autoFocus>
            {t('yes')}
          </Button>
        </DialogActions>}
      </Dialog>
    </div>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  showActions: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
  isOpen: false,
  showActions: true,
  onYesClick: () => {},
  onNoClick: () => {},
};

export default ConfirmDialog;
