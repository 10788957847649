import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M2.6,34.6c4.1,1.1,8.3,2.2,12.9,3.5c-1.1,1.3-1.9,2.4-2.8,3.5c3.3,2,6.8,3.1,10.6,3.5
		c6.9,0.7,13.3-0.9,19.1-4.8c0.7-0.5,1.8-0.8,2.5-0.6c1.7,0.5,1.8,2.7,0.1,3.9c-4.7,3.4-10,5.3-15.8,5.8c-6.6,0.5-12.8-0.7-18.5-3.9
		c-1.1-0.7-1.8-0.4-2.6,0.5c-0.6,0.6-1.2,1.2-1.9,1.7c-0.1,0.1-0.4,0.1-0.9,0.1C4.1,43.5,2.9,39.2,1.8,35C2,34.8,2.3,34.7,2.6,34.6z
		"
          />
          <path
            className="st0"
            d="M40.5,5.3c1.2-1.1,2.4-2.2,3.9-3.6c1.3,4.7,2.4,9,3.8,14c-4.8-1.3-9.1-2.4-13.9-3.7c1.1-1.4,2-2.4,2.9-3.6
		C30,4.5,22.5,3.7,14.8,6.2c-2.6,0.9-4.9,2.3-7.3,3.6C6.2,10.6,5,11,4.1,9.7c-1-1.4-0.3-2.6,1-3.4c10.8-7,21.9-7.6,33.5-2
		C39.2,4.6,39.8,4.9,40.5,5.3z"
          />
          <path
            className="st0"
            d="M26.6,24c4-5.8,8-7.3,12.8-5c-1.9,6.9-5.5,10-12.2,10.6c0.6,2.5,2.1,4.4,4,6c1,0.8,1.7,1.7,0.7,2.9
		c-1,1.1-2,0.3-2.8-0.4c-4.7-4.2-6.7-9.5-5.7-15.7c0.2-1.1,0.4-2.5,2-2.3c1.7,0.2,1.4,1.5,1.2,2.7C26.6,22.9,26.6,23.2,26.6,24z"
          />
          <path
            className="st0"
            d="M20.6,27.5c-6-0.2-7.6-5.4-10.5-9.2c2.8-2.5,8-1.9,10.6,0.5c0.4,0.3,0.7,1,0.7,1.4
		C21.2,22.6,20.9,25,20.6,27.5z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
