import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M8.6,13.9c0.3,0.1,0.6,0.2,0.9,0.2c2.2,0.5,4.3-0.6,5.2-2.6c0.9-2,0.2-4.3-1.7-5.5c-0.5-0.3-0.7-0.7-0.7-1.3
		c-0.1-2.4-2.1-4.2-4.5-4.2c-2.4,0-4.4,1.9-4.5,4.3C3.2,5.4,3,5.7,2.5,6c-1.6,1-2.2,2.5-2,4.4c0.2,1.8,1.6,3.3,3.3,3.7
		c1,0.2,2,0,3.1,0c0,1.5,0,3.1,0,4.8c-0.3,0-0.6,0-0.9,0c-1.5,0-3,0-4.5,0c-0.5,0-1,0.1-1,0.7c0,0.7,0.5,0.8,1,0.8
		c0.1,0,0.2,0,0.3,0c4,0,7.9,0,11.9,0c0.3,0,0.6,0,1,0c-0.1,0.7-0.3,1.2-0.3,1.7c-0.1,0.5-0.3,0.6-0.7,0.6c-1.4,0-2.7,0-4.1,0
		c-2.7,0-5.4,0-8.1,0c-0.5,0-1.1,0-1.1,0.8c0,0.7,0.6,0.8,1.2,0.8c0.8,0,1.6,0,2.5,0c3.3,0,6.5,0,9.9,0c-0.2,0.8-0.3,1.5-0.5,2.3
		c-0.5,0-0.9,0-1.2,0c-3.6,0-7.1,0-10.7,0c-0.6,0-1.1,0.1-1.1,0.8c0.1,0.6,0.5,0.7,1.1,0.7c2.5,0,4.9,0,7.4,0c1.4,0,2.8,0,4.2,0
		c-0.1,0.5-0.2,0.8-0.2,1.2c0,0.9-0.5,1.1-1.3,1.1c-3.3,0-6.7,0-10,0c-0.5,0-1.1,0.1-1.1,0.7c0,0.8,0.6,0.8,1.1,0.8
		c3.2,0,6.5,0,9.7,0c0.3,0,0.7,0,1.1,0c-0.1,0.5-0.3,1-0.3,1.4c0,0.8-0.4,0.9-1.1,0.9c-3.1,0-6.2,0-9.4,0c-0.6,0-1.2,0-1.1,0.8
		c0,0.7,0.6,0.8,1.2,0.8c2.3,0,4.6,0,6.9,0c1,0,2,0,3,0c-0.2,0.8-0.3,1.5-0.5,2.3c-0.4,0-0.8,0-1.2,0c-2.8,0-5.6,0-8.3,0
		c-0.6,0-1.2,0.1-1.1,0.8c0,0.7,0.6,0.7,1.1,0.7c2.7,0,5.4,0,8.2,0c0.3,0,0.6,0,1,0.1c-0.2,0.8-0.3,1.5-0.5,2.3c-0.4,0-0.8,0-1.2,0
		c-2.6,0-5.1,0-7.7,0c-0.5,0-1,0.1-1,0.7c0,0.6,0.4,0.8,1,0.8c1.6,0,3.3,0,4.9,0c1.2,0,2.4,0,3.6,0c-0.1,0.5-0.3,1-0.3,1.4
		c0,0.7-0.3,0.9-1,0.9c-1.2-0.1-2.4,0-3.7,0c-1.2,0-2.4,0-3.7,0c-0.3,0-0.8,0.4-0.8,0.7c-0.1,0.5,0.3,0.8,0.9,0.8c0.4,0,0.8,0,1.2,0
		c2.2,0,4.3,0,6.6,0c-0.1,0.4-0.1,0.7-0.2,1c-0.1,0.6-0.2,1.1,0.5,1.3c0.7,0.1,0.9-0.4,1-0.9c1.6-7.4,3.1-14.7,4.6-22.1
		c0.4-2,0.8-4,1.2-6c1.1,0,2.1,0,3.1,0c-0.1,1.4-0.3,2.7-0.4,4c-0.3,2.8-0.6,5.7-0.9,8.5c-0.4,3.5-0.7,7.1-1.1,10.6
		c-0.2,1.6-0.4,3.3-0.5,4.9c0,0.3,0.4,0.6,0.6,0.9c0.3-0.2,0.6-0.4,0.8-0.7c0.1-0.2,0.1-0.6,0.1-0.9c0.4-4.1,0.9-8.2,1.3-12.2
		c0.4-3.5,0.7-7.1,1.1-10.6c0.2-1.5,0.3-3,0.5-4.5c1.1,0,2.1,0,3.1,0c0,0.4,0,0.7,0,1.1c0,8.8,0,17.6,0,26.4c0,0.6-0.2,1.5,0.8,1.5
		c0.9,0,0.7-0.9,0.7-1.4c0-8.8,0-17.5,0-26.3c0-0.4,0-0.8,0-1.3c0.9,0,1.7,0,2.6,0c0-0.5,0-1,0-1.6c-6.6,0-13.2,0-19.8,0
		C8.6,17.2,8.6,15.6,8.6,13.9z"
          />
          <path
            className="st0"
            d="M48.6,45.7c-0.1,0-0.3,0-0.4,0c-1.9,0-3.7,0-5.6,0c-2.1,0-2.1,0-2.5-2c0-0.1,0-0.2,0-0.3c2.5,0,5.1,0,7.6,0
		c0.3,0,0.7,0.1,1,0c0.3-0.1,0.6-0.5,0.9-0.7c-0.3-0.3-0.5-0.6-0.9-0.7c-0.2-0.1-0.4,0-0.7,0c-2.7,0-5.5,0-8.3,0
		c-0.2-0.8-0.3-1.5-0.5-2.3c0.4,0,0.7,0,0.9,0c2.8,0,5.6,0,8.4,0c0.5,0,0.9-0.2,1-0.7c0-0.6-0.4-0.8-0.9-0.8c-0.2,0-0.4,0-0.6,0
		c-1.6,0-3.1,0-4.7,0c-1.5,0-2.9,0-4.5,0c-0.2-0.8-0.3-1.5-0.5-2.3c0.4,0,0.7,0,1,0c3,0,6,0,8.9,0c0.6,0,1.2-0.1,1.2-0.8
		c0-0.7-0.6-0.7-1.2-0.7c-3.1,0-6.2,0-9.4,0c-0.3,0-0.6,0-0.9,0c-0.2-0.8-0.3-1.5-0.5-2.3c1.6,0,3,0,4.5,0c2.2,0,4.3,0,6.5,0
		c0.5,0,0.9-0.2,1-0.7c0-0.6-0.4-0.8-0.9-0.8c-0.2,0-0.4,0-0.6,0c-2.9,0-5.7,0-8.6,0c-2.2,0-2.2,0-2.6-2.1c0,0,0-0.1,0.1-0.2
		c0.4,0,0.8,0,1.2,0c3.5,0,6.9,0,10.4,0c0.6,0,1-0.2,1-0.8c0-0.6-0.4-0.8-1-0.8c-2.4,0-4.9,0-7.3,0c-1.6,0-3.1,0-4.7,0
		c-0.2-0.8-0.3-1.5-0.5-2.3c0.4,0,0.7-0.1,1-0.1c3.6,0,7.3,0,10.9,0c0.3,0,0.7,0.1,0.9,0c0.3-0.1,0.7-0.4,0.7-0.7
		c0-0.2-0.4-0.6-0.6-0.7c-0.3-0.1-0.7-0.1-1-0.1c-4,0-8.1,0-12.2,0c-0.1-0.8-0.3-1.5-0.4-2.3c0.5,0,0.9,0,1.2,0c3.9,0,7.8,0,11.7,0
		c0.3,0,0.6,0.1,0.7-0.1c0.3-0.2,0.6-0.5,0.6-0.8c0-0.2-0.4-0.5-0.6-0.7c-0.1-0.1-0.4,0-0.6,0c-4.8,0-9.6,0-14.4,0
		c-0.3,0-0.5,0-0.9,0c0.2,0.6-0.4,1.4,0.6,1.6c0.1,0,0.1,0.2,0.1,0.4c0.4,1.9,0.8,3.7,1.2,5.6c1.2,5.6,2.3,11.1,3.5,16.7
		c0.4,1.8,0.8,3.7,1.2,5.5c0.1,0.5,0.3,1,0.9,0.9c0.6-0.1,0.7-0.6,0.6-1.1c-0.1-0.4-0.1-0.7-0.2-1.1c0.4,0,0.7,0,1,0
		c2.2,0,4.5,0,6.7,0c0.5,0,0.9-0.2,0.9-0.7C49.5,45.9,49,45.7,48.6,45.7z"
          />
          <path
            className="st0"
            d="M30.7,29.2c0.6,0,0.7-0.5,0.7-1c0-1.9,0-3.7,0-5.6c0-2.7,0-5.4,0-8.2c3,0,5.9,0,8.8,0c1,0,1.2-0.3,1.2-1.2
		c0-3.8,0-7.6,0-11.4c0-1-0.2-1.2-1.1-1.2c-6.4,0-12.9,0-19.3,0c-0.7,0-1,0.3-1,1c0,3.9,0,7.8,0,11.6c0,1,0.2,1.1,1.2,1.1
		c2.6,0,5.2,0,7.8,0c0.3,0,0.6,0,0.9,0c0,4.7,0,9.3,0,13.8C29.9,28.7,30.1,29.2,30.7,29.2z M21.6,12.7c0-3.5,0-7.1,0-10.6
		c6.1,0,12.2,0,18.3,0c0,3.6,0,7.1,0,10.6C33.8,12.7,27.7,12.7,21.6,12.7z"
          />
          <path
            className="st0"
            d="M33.1,45.7c-0.4-3.5-0.7-7.1-1.1-10.6c-0.2-1.5-0.3-2.9-0.5-4.3c-0.5,0-1,0-1.5,0c0.1,0.6,0.1,1.2,0.2,1.8
		c0.4,3.6,0.8,7.2,1.1,10.8c0.2,1.7,0.4,3.5,0.6,5.2c0.1,0.5,0.3,1,0.9,0.9c0.5-0.1,0.7-0.5,0.6-1C33.3,47.5,33.2,46.6,33.1,45.7z"
          />
          <path
            className="st0"
            d="M34,5.1c-1.1-0.1-2.2,0-3.2,0c0,0,0,0,0,0c-1.1,0-2.3-0.1-3.4,0c-0.3,0-0.6,0.5-0.9,0.7
		c0.3,0.3,0.5,0.8,0.8,0.8c2.2,0.1,4.5,0,6.7,0c0.5,0,0.9-0.3,0.8-0.8C34.8,5.5,34.3,5.2,34,5.1z"
          />
          <path
            className="st0"
            d="M32.9,8.2c-0.7-0.1-1.4,0-2.1,0c-0.7,0-1.4,0-2.1,0c-0.5,0-0.9,0.1-0.9,0.7c0,0.6,0.3,0.8,0.9,0.8
		c1.4,0,2.9,0,4.3,0c0.5,0,0.9-0.3,0.8-0.8C33.7,8.6,33.2,8.2,32.9,8.2z"
          />
          <path
            className="st0"
            d="M23.8,4.7c0.3-0.2,0.7-0.5,0.7-0.8c0-0.2-0.3-0.6-0.7-1.1c-0.4,0.5-0.7,0.8-0.7,1.1
		C23.2,4.2,23.6,4.5,23.8,4.7z"
          />
          <path
            className="st0"
            d="M23.2,10.8c0,0.2,0.4,0.7,0.7,0.7c0.3,0,0.6-0.3,1.1-0.7c-0.5-0.4-0.8-0.7-1.1-0.7
		C23.6,10.1,23.2,10.6,23.2,10.8z"
          />
          <path
            className="st0"
            d="M37,10.8c0,0.2,0.4,0.7,0.7,0.7c0.3,0,0.6-0.4,1.1-0.7c-0.5-0.4-0.8-0.7-1.1-0.7C37.4,10.1,37,10.6,37,10.8z"
          />
          <path
            className="st0"
            d="M38.4,3.9c-0.3-0.2-0.5-0.6-0.8-0.7c-0.3,0-0.5,0.4-1,0.7c0.5,0.4,0.8,0.7,1.1,0.7C37.9,4.6,38.1,4.2,38.4,3.9
		z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
