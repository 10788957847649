import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import appEn from 'locales/en/acre.json';
import formEn from 'locales/en/form.json';
import pageEn from 'locales/en/page.json';
import {isDevelopment} from 'utils/util';

i18n
  .use(initReactI18next) 
  .init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        app: appEn,
        form: formEn,
        page: pageEn,
      },
    },
    // files to load
    ns: ['app', 'form', 'page'],
    defaultNS: 'app',
    interpolation: {
      escapeValue: false,
    },
    debug: true && isDevelopment(),
  });

export default i18n;
