import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M30.5,49.5c-2.6-1.4-5.2-2.7-7.8-4.2c-0.9-0.5-1.7-1.2-2.5-1.9c0.1-0.2,0.2-0.3,0.4-0.5
		c-1.2,0.4-2.4,0.7-3.6,1.1c-2.5,0.7-4.6,0.2-6.6-1.5c-2.2-1.9-3.5-4.4-4.6-7.1c-2.9-7.5-2.7-14.9,1-22c0.6-1.2,1.6-2.3,2.4-3.5
		c0.5-0.7,1.2-0.9,2-0.6c1.8,0.8,3.5,1.6,5.4,2.4c0.7-0.9,1.4-2,2.3-3.1c3.3-3.9,7.2-6.8,12.2-8.2c0.4,0,0.7,0,1.1,0
		c4.3,4.7,5,10.5,5.2,16.5c1-0.2,2-0.5,3-0.7c-0.2,0.7-0.3,1.2-0.5,1.9c2.7-1,3,1.1,3.8,2.6c0.5-0.3,0.8-0.6,1.3-0.9
		c0.1,0.2,0.2,0.3,0.2,0.4c1.7,6.9,1.1,13.4-2.4,19.6c-2.4,4.3-5.3,8.2-10.4,9.6C31.8,49.5,31.2,49.5,30.5,49.5z M37.7,19
		c-0.5,0.2-0.9,0.3-1.2,0.5c-6.7,2.8-11.1,7.7-13.6,14.4c-0.6,1.7-1,3.5-1.2,5.3c-0.2,2.5,0.8,4.3,3.1,5.4c1.3,0.6,2.5,1.4,3.8,2.2
		c1.8,1.2,3.7,1.3,5.5,0.2c1.1-0.7,2.3-1.5,3.2-2.4c3.5-4,6.1-8.5,6.8-13.9c0.3-2.4,0.3-4.7-0.1-7.3c-3.4,2.8-6.7,5.4-10.1,8.1
		c-0.1-0.1-0.1-0.1-0.2-0.2C35,27.2,36.3,23.2,37.7,19z M19.9,31.6c0.1-0.9,0.2-1.6,0.3-2.4C21,24,22.2,19,24,14.1
		c0.2-0.6,0.6-1.2,1.3-0.9c0.8,0.3,0.6,0.9,0.4,1.5c-0.4,1.3-0.9,2.5-1.3,3.8c-1.2,4-2.1,8.2-2.6,12.4c0.2-0.1,0.2-0.1,0.3-0.2
		c2.7-5.8,7-10,12.7-12.7c0.3-0.1,0.6-0.7,0.6-1c-0.2-2.6-0.3-5.1-0.8-7.7c-0.4-2.3-1.4-4.5-2.9-6.5c-1,1.7-1.9,3.2-2.8,4.8
		c-0.3,0.6-0.8,0.9-1.4,0.6c-0.6-0.3-0.6-0.9-0.2-1.5c0.7-1.2,1.3-2.3,2.1-3.7c-2.5,1-4.4,2.3-6.3,4c-5.6,5-8.2,11.3-8.3,18.7
		C14.8,29.3,16.7,31.3,19.9,31.6z M15.8,13.5c-1.6-0.8-3.1-1.5-4.6-2.2c-0.2-0.1-0.8,0-0.9,0.2c-0.8,1-1.5,2.1-2.3,3.2
		c0.1,0.1,0.1,0.2,0.2,0.2c0.8-0.1,1.6-0.2,2.4-0.4c0.7-0.2,1.4,0,1.4,0.7c0,0.4-0.6,1-1,1.1c-1,0.3-2.1,0.3-3.2,0.5
		c-0.3,0.1-0.8,0.4-0.9,0.7c-0.5,2.1-1.1,4.3-1.3,6.5c-0.4,5.1,1,9.8,3.4,14.1c0.7,1.2,1.7,2.4,2.8,3.2c0.8,0.6,2.1,1.1,3.1,1.1
		c1.7-0.1,3.3-0.8,4.6-1.1c0.3-2.6,0.5-4.9,0.8-7.3c-0.7-0.2-1.6-0.6-2.5-0.8c-1.9-0.6-3.3-1.7-4.1-3.6c-0.8-2-1.1-4-0.8-6.2
		C13.3,20,14.3,16.9,15.8,13.5z M37.4,26.1c1.4-1.1,2.6-2.1,3.7-3c1.3-1.1,1.3-1.1,0.5-2.6c-0.2-0.3-0.6-0.6-0.9-0.5
		c-0.5,0.1-1.3,0.2-1.4,0.6C38.6,22.2,38,24,37.4,26.1z"
          />
          <path
            className="st0"
            d="M32,24.6c0.3,0.3,0.8,0.5,0.8,0.8c0,0.4-0.2,0.9-0.5,1.2c-1.3,1.3-2.7,2.5-3.9,3.9c-1.6,2-2.5,4.5-2.7,7.1
		c0,0.7-0.2,1.4-1.1,1.3c-0.8-0.1-0.8-0.8-0.8-1.4c0.5-5.1,2.9-9.1,7-12.1C31.1,25.1,31.5,24.9,32,24.6z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
