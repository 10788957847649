import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M22.8,17.5c1.5,0,2.9,0,4.4,0c0.1-0.8,0.1-1.4,0.2-2.1c0.3-3,2-5.2,4.7-5.9c2.8-0.8,5.3-0.1,7,2.2
	c3.1,4.1,6,8.4,8.8,12.7c2.5,3.9,1.9,8.9-1.1,12.5c-2.8,3.4-7.5,4.8-11.7,3.5c-4.6-1.5-7.6-5.5-7.8-10.2c-0.1-1.4,0-2.9,0-4.5
	c-1.6,0-3,0-4.8,0c0,1.3,0,2.6,0,3.9c-0.1,5-3,9.1-7.6,10.6c-4.3,1.5-9.1,0.1-12.1-3.6c-3-3.7-3.3-8.7-0.5-13
	c2.5-3.9,5.2-7.7,7.9-11.5c1.8-2.6,4.5-3.6,7.2-2.9c2.9,0.8,4.7,2.9,5,6.3C22.7,16.2,22.7,16.7,22.8,17.5z M38.4,23.1
	c-3.7,0-6.7,3.1-6.7,6.8c0,3.7,3,6.7,6.8,6.7c3.7,0,6.7-3,6.7-6.8C45.2,26.1,42.1,23.1,38.4,23.1z M11.5,23.1
	c-3.7,0-6.7,3.1-6.7,6.8c0,3.8,3,6.7,6.8,6.7c3.7,0,6.8-3.1,6.7-6.8C18.3,26.1,15.2,23.1,11.5,23.1z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
