import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <g>
          <path
            className="st0"
            d="M4.8,24.9c0,5.6,0,11.2,0,16.8c0,2.7,1.3,4,4,4c4.1,0,8.3,0,12.4,0c0.4,0,0.7,0,1.1,0.1
				c0.9,0.3,1.4,0.9,1.3,1.8c-0.1,0.9-0.6,1.4-1.6,1.6c-0.3,0-0.5,0-0.8,0c-4.3,0-8.5,0-12.8,0c-4.3,0-7.3-2.9-7.3-7.3
				c0-11.4,0-22.9,0-34.3c0-4.2,3-7.2,7.2-7.2c7.5,0,14.9,0,22.4,0c4,0,7.1,3,7.1,7c0,3.7,0,7.4,0,11.1c0,1.4-0.7,2.2-1.7,2.2
				c-1.1,0-1.7-0.8-1.8-2.2c0-3.5,0-7.1,0-10.6c0-2.8-1.3-4.1-4.1-4.1C23.2,4,15.9,4,8.7,4C6.2,4,4.8,5.4,4.8,7.9
				C4.8,13.6,4.8,19.3,4.8,24.9C4.8,24.9,4.8,24.9,4.8,24.9z"
          />
          <path
            className="st0"
            d="M41.5,28.2c3.9-0.9,3.9-0.9,5.9,2.5c0.1,0.2,0.2,0.4,0.3,0.6c1.4,2.5,1.4,3-0.7,5.1
				c-0.2,0.2-0.4,0.3-0.6,0.5c0.6,0.8,1.3,1.5,1.8,2.2c0.6,0.7,0.6,1.5,0.1,2.3c-0.7,1.1-1.3,2.3-2,3.4c-0.7,1.2-1.2,1.5-2.7,1.3
				c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3-0.1-0.7-0.2-1-0.3c-0.4,0.9-0.7,1.8-1,2.7c-0.3,0.8-1,1.2-1.9,1.2c-1.3,0-2.7-0.1-4,0
				c-1.2,0.1-1.9-0.5-2.3-1.5c-0.3-0.7-0.5-1.5-0.8-2.3c-0.7,0.1-1.5,0.3-2.3,0.5c-1.2,0.2-2-0.2-2.6-1.2c-0.6-1.1-1.2-2.2-1.9-3.2
				c-0.7-1-0.6-2,0.2-2.9c0.5-0.6,1-1.2,1.5-1.8c-0.6-0.7-1.2-1.4-1.7-2.1c-0.6-0.8-0.6-1.6-0.1-2.4c0.7-1.1,1.4-2.2,2-3.4
				c0.6-1.1,1.4-1.5,2.6-1.3c0.8,0.1,1.6,0.3,2.3,0.4c0.3-0.9,0.6-1.8,1-2.7c0.3-0.8,1-1.2,1.9-1.2c1.3,0,2.6,0.1,3.9,0
				c1.2-0.1,2,0.4,2.4,1.6C40.9,26.6,41.2,27.4,41.5,28.2z M35.1,27.8c-0.1,0.4-0.2,0.8-0.1,1.1c0.2,1-0.3,1.6-1.1,2
				c-0.4,0.2-0.8,0.4-1.1,0.7c-0.8,0.5-1.7,0.7-2.5,0c-0.6-0.4-1.1-0.4-1.4,0.3c-0.1,0.3-0.3,0.5-0.4,0.8c-0.5,0.7-0.3,1.1,0.4,1.5
				c0.5,0.3,1,0.9,1.1,1.4c0.2,0.8,0.1,1.7,0,2.5c-0.1,0.5-0.4,1-0.7,1.3c-1.2,1-1.2,0.9-0.5,2.3c0.5,0.9,0.9,0.9,1.7,0.4
				c0.4-0.3,1.1-0.4,1.6-0.2c0.8,0.3,1.6,0.8,2.3,1.3c0.4,0.3,0.6,0.8,0.7,1.3c0.2,1.5,0.1,1.5,1.8,1.5c0.9,0,1.2-0.3,1.2-1.2
				c0-0.5,0.2-1.2,0.5-1.5c0.7-0.6,1.5-1,2.3-1.4c0.4-0.2,1-0.1,1.5,0.1c1.4,0.6,1.4,0.6,2.1-0.6c0.2-0.3,0.4-0.6,0.6-1.1
				c-1.3-0.7-2.1-1.6-2.1-3.3c0-1.8,0.9-2.6,2.2-3.4c-0.3-0.5-0.5-0.8-0.7-1.2c-0.7-1.2-0.7-1.2-2-0.6c-0.4,0.2-1,0.3-1.4,0.1
				c-0.8-0.3-1.6-0.8-2.4-1.4c-0.3-0.2-0.6-0.8-0.6-1.2c-0.2-1.6-0.2-1.6-1.7-1.6C35.9,27.8,35.5,27.8,35.1,27.8z"
          />
          <path
            className="st0"
            d="M19.6,13.2c-2.4,0-4.7,0-7.1,0c-1.4,0-2.2-0.6-2.2-1.7c0-1.1,0.8-1.8,2.1-1.8c4.8,0,9.5,0,14.3,0
				c1.4,0,2.2,0.6,2.2,1.7c0,1.1-0.7,1.7-2.1,1.8C24.4,13.2,22,13.2,19.6,13.2C19.6,13.2,19.6,13.2,19.6,13.2z"
          />
          <path
            className="st0"
            d="M19.6,17.6c2.4,0,4.8,0,7.2,0c1.4,0,2.2,0.6,2.2,1.7c0,1.1-0.8,1.8-2.2,1.8c-4.7,0-9.5,0-14.2,0
				c-1.4,0-2.2-0.6-2.2-1.7c0-1.1,0.8-1.8,2.2-1.8C14.9,17.6,17.2,17.6,19.6,17.6C19.6,17.6,19.6,17.6,19.6,17.6z"
          />
          <path
            className="st0"
            d="M16.8,25.4c1.5,0,3,0,4.5,0c1.1,0,1.9,0.7,1.9,1.6c0,1-0.7,1.8-1.9,1.8c-3.1,0-6.1,0-9.2,0
				c-1.1,0-1.8-0.7-1.9-1.7c0-1.1,0.7-1.7,1.9-1.8C13.8,25.3,15.3,25.4,16.8,25.4C16.8,25.4,16.8,25.4,16.8,25.4z"
          />
          <path
            className="st0"
            d="M15.2,36.7c-1,0-2,0-3.1,0c-1.1,0-1.8-0.7-1.8-1.7c0-1,0.7-1.7,1.8-1.7c2.1,0,4.1,0,6.2,0
				c1.1,0,1.8,0.7,1.8,1.7c0,1-0.7,1.7-1.9,1.7c-0.5,0-1,0-1.5,0C16.3,36.7,15.7,36.7,15.2,36.7z"
          />
          <path
            className="st0"
            d="M41.6,37c0,2.9-2.4,5.2-5.2,5.2c-2.9,0-5.3-2.4-5.3-5.3c0-2.9,2.4-5.2,5.4-5.2C39.4,31.7,41.7,34.1,41.6,37z
				 M36.3,38.6c1,0.1,1.8-0.6,1.8-1.6c0.1-1-0.6-1.8-1.6-1.8c-1,0-1.8,0.7-1.8,1.6C34.6,37.7,35.4,38.6,36.3,38.6z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Icon;
