import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M49.5,19.5c-0.1-0.1-0.2-0.2-0.3-0.2h-3.4l3.1-4.5c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.1-0.2-0.2-0.3-0.2h-5l1-4
			c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2l-0.1,0c-0.1,0-0.2,0-0.3,0l-0.8,0c-0.9,0-1.7,0-2.6,0c0,0,0,0,0,0
			c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0-0.3,0-0.6,0-1l0-0.5c0-0.2-0.2-0.4-0.4-0.4l-2.8,0l-4.9,0c-0.3,0-0.5,0-0.8,0
			c-0.6,0-1.1,0.1-1.6-0.1c-0.4-0.2-0.8-0.6-1.1-1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.3-0.7-0.5-1.1-0.5c-2.2,0-4.3,0-6.5,0l-1.5,0
			c-0.1,0-0.3,0-0.4,0l-0.2,0c-0.2,0-0.3,0.2-0.3,0.3l0,0.1c0,0.1,0,0.1,0,0.2l0,2.8c-1.6,0.5-3.1,1.2-4.5,2.2
			C8.7,14.8,6,22.5,10.4,29.6c-0.1,0.1-0.3,0.3-0.4,0.4l-8.4,8.3c-0.9,0.9-1.3,2-1.1,3.2c0.2,1.5,1.1,2.5,2.5,2.9
			c0.4,0.1,0.8,0.2,1.1,0.2c0.9,0,1.8-0.4,2.5-1.1l8.9-9.2c7.3,3.3,14.3,0.2,17.6-4.6l4.9,0c0.7,0,1.3-0.2,1.9-0.8
			c1.5-1.5,3.1-2.9,4.6-4.4l4.8-4.6C49.5,19.8,49.5,19.6,49.5,19.5z M13.9,33.2l-0.1,0.1c-0.3,0.3-0.6,0.5-0.9,0.8
			c-1.8,1.8-3.6,3.5-5.3,5.3l-2.3,2.2C4.9,42,4.5,42.2,4,42.2c-0.3,0-0.6-0.1-0.9-0.4c-0.6-0.6-0.5-1.4,0.2-2.1l8.3-8.5L13.9,33.2z
			 M44.6,16.5l-0.2,0.3c-0.5,0.9-1,1.7-1.5,2.4c0,0-0.1,0.1-0.4,0.1c-2.3,0-4.6,0-6.9,0l-0.4,0c-0.2-1-0.5-1.9-0.9-2.8L44.6,16.5
			C44.6,16.5,44.6,16.5,44.6,16.5z M19.7,10.9L19.7,10.9c0.3-0.1,0.5-0.1,0.8-0.1l0.3,0c0.3,0,0.7-0.1,1-0.1c0.4,0,0.8,0,1.3,0.1
			c0.1,0,0.1,0,0.2,0l0.1,0c0.3,0,0.7,0.1,1,0.2l0.3,0.1c0.3,0.1,0.7,0.2,1.1,0.3l0.1,0c0.4,0.2,0.8,0.3,1.1,0.5
			c1.4,0.7,2.6,1.7,3.6,2.9c0.1,0.1,0.2,0.3,0.3,0.4l0.3,0.4c0.2,0.3,0.3,0.5,0.5,0.8c0.6,1.1,1.1,2.2,1.3,3.4
			c0.1,0.4,0.1,0.8,0.2,1.1c0,0.4,0.1,0.8,0.1,1.2c0,0.4,0,0.7,0,1.1c0,0.1,0,0.2,0,0.3l0,0.1c0,0.2-0.1,0.4-0.1,0.6
			c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.3-0.1,0.5c0,0.2-0.1,0.4-0.2,0.5c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.1,0.4-0.3,0.7l0,0.1
			c-0.4,0.8-0.8,1.5-1.3,2.2c-2.2,2.8-5.5,4.4-9.1,4.4c-2.9,0.1-5.8-1.1-7.9-3.3c-2.2-2.2-3.4-5.1-3.4-8.1c0-5.3,3.8-9.9,9-11.1
			C19.5,10.9,19.6,10.9,19.7,10.9z M41.5,12.2c-0.1,0.5-0.3,1-0.4,1.5c-0.1,0.3-0.1,0.4-0.5,0.4c-0.8,0-1.7,0-2.5,0
			c-0.5,0-1,0-1.6,0l-1.6,0l-2,0c-0.6-0.8-1.2-1.6-1.9-2.2c0,0,0,0,0,0h10.6C41.6,12.1,41.5,12.2,41.5,12.2z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
