import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M49,48.7c-0.8,0-1.3,0-1.8,0c-14.8,0-29.6,0-44.4,0c-1.5,0-1.9-0.5-2.1-1.9c-0.8-6.2,1.1-11,5.7-13.3
		c1.9-0.9,4.1-1.4,6.2-1.5c8.3-0.2,16.5-0.1,24.8-0.1c7.4,0,12.3,5,12.1,12.5C49.5,45.9,49.2,47.2,49,48.7z"
        />
        <path
          className="st0"
          d="M24.8,28.4c-7.4-0.2-13.6-6.6-13.4-13.9c0.2-7.4,6.6-13.5,14-13.3C32.5,1.4,38.8,7.8,38.6,15
		C38.5,22.4,32.1,28.6,24.8,28.4z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
