import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupportOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white
  },
  inner: {
    padding: theme.spacing(6, 3),
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto'
  },
  content: {
    marginTop: theme.spacing(6)
  }
}));

const FAQ = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const faqs = [
    {
      title: 'Do I have to purchase a plan in order to test drive AcreFx',
      description:
        'Absolutely not! We belive customer has to be satisfied and to understand if there is a need and benefit from using our tools. Therefore, AcreFx team is happy to give you a chance to test ride the product without charge.'
    },
    {
      title:
        'I don\'t have a company, can I still register and try it?',
      description:
        'Every user is a person. We want you to know we understand that. Company or an individual, you are welcome to AcreFx family!'
    },
    {
      title: 'Are you providing support for setting up my project?',
      description:
        'Yes, we offer email support for all our customers & even skype meetings for our advanced license customers.'
    }
  ];

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.inner}>
        <Typography
          align="center"
          variant="h3"
        >
          FAQ
        </Typography>
        <div className={classes.content}>
          <List disablePadding>
            {faqs.map(faq => (
              <ListItem key={uuid()}>
                <ListItemIcon>
                  <ContactSupportIcon />
                </ListItemIcon>
                <ListItemText
                  primary={faq.title}
                  primaryTypographyProps={{ variant: 'h5' }}
                  secondary={faq.description}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
};

FAQ.propTypes = {
  className: PropTypes.string
};

export default FAQ;
