import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M21,18.7c0.2,0,0.4,0,0.6,0c2.5,0,4.5-1.5,5.1-3.8c0.2-0.7,0.2-1.4,0.3-2.2l-0.6,0l0.6,0l0-0.6
		c0-0.1,0-0.3-0.1-0.4l0-0.7c0-0.5,0-1,0-1.5c-0.1-2.8-2.4-4.9-5.2-4.9c-2.8,0-5.1,2.1-5.3,4.9c-0.1,1.4-0.1,2.9,0,4.4
		C16.6,16.3,18.6,18.4,21,18.7z M19,9.5c0.1-1.3,1.2-2.4,2.7-2.4c1.4,0,2.5,1.1,2.6,2.5c0,0.5,0,1,0,1.5l0,0.6l0,0.6l0.6,0l-0.6,0.1
		c0,0.4,0,0.9,0,1.4c-0.1,1.4-1.2,2.4-2.6,2.4c-1.4,0-2.6-1-2.6-2.4C19,12.3,19,10.9,19,9.5z"
        />
        <path
          className="st0"
          d="M4.3,15.4c0.6,2,2.4,3.2,4.9,3.3c2.8,0,4.5-1,5.2-3.2c0.1-0.2,0.1-0.4,0.2-0.7c0-0.2,0-0.4-0.1-0.5
		c-0.1-0.1-0.3-0.2-0.5-0.2h-1.4c-0.3,0-0.5,0.2-0.6,0.4c-0.4,1.2-0.9,1.6-2.6,1.6H9.1c-0.2,0-0.3,0-0.4,0c-1.2-0.1-2-0.8-2-2
		c0-1.4,0-2.9,0-4.9c0-0.8,0.4-1.4,1.1-1.8c1.6-0.8,3.4-0.2,4.1,1.4c0.1,0.2,0.3,0.4,0.6,0.4h1.4c0.2,0,0.4-0.1,0.5-0.2
		c0.1-0.1,0.2-0.3,0.1-0.5c-0.3-1.8-1.6-3.3-3.4-3.6c-0.7-0.2-1.4-0.2-2.1-0.2H8.8c-2.7,0-4.7,2-4.7,4.7c0,1.7,0,3.3,0,4.8
		C4.1,14.5,4.1,15,4.3,15.4z"
        />
        <path
          className="st0"
          d="M33.7,18.9c-0.7,0.2-1.6,0.5-2.4,0.8c-1.4,0.5-2.3,1.8-2.1,3.1c0.2,1.6,1.3,2.7,2.8,2.8h0c0.5,0,1,0,1.7,0
		c0.4,0,0.9,0,1.4,0l0.6,0c0.3,0,0.6-0.3,0.6-0.6v-1.3c0-0.2-0.1-0.3-0.2-0.4s-0.3-0.2-0.4-0.2l-1.3,0c-0.7,0-1.2,0-1.7,0
		c-0.2,0-0.4-0.1-0.6-0.2c-0.1,0-0.2-0.1-0.3-0.1l0-0.1c0.1,0,0.1-0.1,0.2-0.2c0.2-0.1,0.3-0.3,0.5-0.4c0.4-0.1,0.8-0.3,1.1-0.4
		c0.4-0.1,0.9-0.3,1.3-0.4c1.5-0.6,2.3-2.2,2-4c-0.3-1.5-1.6-2.5-3.2-2.5l-0.1,0c-0.6,0-1.1,0-1.5,0c-1,0.1-1.8,0.6-2.4,1.4
		c-0.3,0.4-0.5,0.9-0.5,1.4c0,0.2,0,0.3,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2h1.4c0.3,0,0.5-0.2,0.6-0.4c0-0.1,0.1-0.2,0.2-0.3
		c0.1-0.1,0.4-0.3,1-0.2c0.1,0,0.3,0,0.5,0c0.6,0,0.9,0.2,0.9,0.7C34.4,18.5,34.2,18.8,33.7,18.9z"
        />
        <path
          className="st0"
          d="M48.3,23.6c-1.7,0-3.3,0-5,0l-0.9,0c-0.6,0-1,0.3-1.2,0.9c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,0.7-0.6,1.1-1.1,1.5
		c-0.8,0.5-1.5,1.1-2.1,1.5c-1.2,0.9-1.9,1.9-2.3,3c-0.1,0.4-0.3,0.6-0.7,0.8c-1,0.5-2.1,0.7-3.3,0.5c-0.5-0.1-0.7-0.3-0.8-0.7
		c-0.4-1.5-1.3-2.6-2.9-3.6l-0.3-0.2c-0.3-0.2-0.7-0.4-1-0.6c-1.4-1-2.2-1.8-2.7-2.9c-0.3-0.6-0.7-0.8-1.3-0.8c-1.1,0-2.3,0-3.4,0
		l-1.6,0c-0.2,0-0.4,0-0.5,0c-0.6,0.1-1,0.5-1,1.1c0,0.6,0.4,1,1,1.1c0.1,0,0.2,0,0.4,0l1.2,0c0.9,0,1.9,0,2.9,0
		c0.6,0,0.7,0.1,0.8,0.4c0,0.1,0.1,0.2,0.2,0.3c1,1.3,2.3,2.4,3.9,3.4c1.2,0.7,1.9,1.5,2.2,2.6c0.3,1.2,1.2,1.9,2.5,2.2
		c1.7,0.3,3.3,0.1,4.8-0.6c1-0.5,1.6-1.1,1.8-2c0.2-0.8,0.7-1.4,1.4-2c0.6-0.5,1.3-1,1.9-1.4c1.1-0.7,1.8-1.6,2.2-2.8
		c1,0,1.9,0,2.9,0h1.1v17.2H2.8V25.9h8.5c0.1,0,0.3,0,0.4,0c0.6-0.1,1-0.6,0.9-1.2c0-0.5-0.4-1-1-1.1c-0.1,0-0.2,0-0.4,0H1.9
		c-1,0-1.4,0.4-1.4,1.4l0,19.1c0,1,0.4,1.4,1.4,1.4h46.2c1,0,1.4-0.4,1.4-1.4l0-19.1C49.5,23.8,48.8,23.6,48.3,23.6z"
        />
        <path
          className="st0"
          d="M8.5,35.2c0.4,0,0.8-0.2,1.1-0.6c0.3-0.3,0.5-0.7,0.6-1c0.1-0.4,0.2-0.8,0.3-1.3l0.1-0.6c0-0.1,0-0.2,0-0.3
		L10.5,31c-0.1-0.5-0.2-0.9-0.4-1.4c-0.3-0.8-0.9-1.3-1.6-1.3c0,0,0,0,0,0c-1,0-1.5,0.9-1.6,1.3c-0.4,0.9-0.5,1.9-0.4,3
		c0.1,0.6,0.3,1.4,0.8,2.1C7.7,35,8.1,35.2,8.5,35.2C8.5,35.2,8.5,35.2,8.5,35.2z"
        />
        <path
          className="st0"
          d="M32,40.5c0.6,0,1.3-0.3,2-0.9c0.7-0.6,1.1-1.3,1.1-2c0-0.6-0.2-0.9-0.4-1.1c-0.2-0.2-0.5-0.4-1.1-0.4
		c-1.4,0-3,1.4-3.1,2.8c0,0.5,0.1,0.8,0.3,1.1c0.2,0.3,0.6,0.5,1,0.5C32,40.5,32,40.5,32,40.5z"
        />
        <path
          className="st0"
          d="M17.7,39.9C17.7,39.9,17.7,39.9,17.7,39.9c1.2,0,2.2-1,2.2-2.3c0-1.1-0.8-2-1.9-2c-0.6,0-1.1,0.2-1.6,0.6
		c-0.5,0.4-0.7,1.1-0.7,1.7C15.8,39,16.6,39.9,17.7,39.9z"
        />
        <path
          className="st0"
          d="M40.3,39.9c0,0.5,0.2,1,0.5,1.3c0.3,0.4,0.8,0.5,1.3,0.5c0,0,0,0,0,0c0.5,0,1-0.2,1.3-0.5
		c0.4-0.4,0.5-0.8,0.5-1.3c0-0.5-0.2-1-0.6-1.3c-0.4-0.4-0.8-0.5-1.3-0.5C41.2,38.1,40.3,38.9,40.3,39.9z"
        />
        <path
          className="st0"
          d="M13.2,30c0,0.9,0.8,1.6,1.6,1.6c0,0,0,0,0,0c0.9,0,1.6-0.7,1.6-1.6c0-0.4-0.2-0.8-0.5-1.2
		c-0.3-0.3-0.7-0.5-1.2-0.5c0,0,0,0,0,0C13.9,28.4,13.2,29.2,13.2,30z"
        />
        <path
          className="st0"
          d="M23.9,32c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.2-0.9-0.5-1.2c-0.3-0.3-0.7-0.5-1.2-0.5
		c0,0,0,0,0,0c-0.9,0-1.6,0.7-1.6,1.6C22.2,31.2,23,32,23.9,32C23.9,32,23.9,32,23.9,32z"
        />
        <path
          className="st0"
          d="M44.4,32.7c0.4,0,0.8-0.1,1.1-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.9-0.7-1.7-1.6-1.7c0,0,0,0,0,0
		c-0.9,0-1.7,0.7-1.7,1.6c0,0.9,0.6,1.6,1.5,1.7C44.4,32.7,44.4,32.7,44.4,32.7z"
        />
        <path
          className="st0"
          d="M6.8,38.3c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.9,0.7,1.6,1.6,1.6c0,0,0,0,0,0
		c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.2C8.4,39.1,7.7,38.3,6.8,38.3C6.8,38.3,6.8,38.3,6.8,38.3z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
