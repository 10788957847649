import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M2,8.4h7.1c0.7,2.2,2.8,3.8,5.2,3.8s4.5-1.6,5.2-3.8H48c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5H19.6
		c-0.6-2.4-2.8-4.1-5.3-4.1S9.6,2.9,9,5.3H2C1.2,5.3,0.5,6,0.5,6.9C0.5,7.7,1.2,8.4,2,8.4z M11.9,6.3c0.2-1.1,1.2-2,2.4-2
		s2.2,0.8,2.4,2c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4-0.1,0.7c-0.3,1-1.3,1.8-2.3,1.8s-2-0.7-2.3-1.8c-0.1-0.2-0.1-0.4-0.1-0.7
		C11.9,6.6,11.9,6.4,11.9,6.3z"
        />
        <path
          className="st0"
          d="M48,23.6h-8.2c-0.6-2.4-2.8-4.1-5.3-4.1s-4.7,1.7-5.3,4.1H4.5c-2.3,0-4,0.1-4,1.5c0,1.4,1.7,1.5,4,1.5h24.8
		c0.7,2.2,2.8,3.8,5.2,3.8c2.4,0,4.5-1.6,5.2-3.8H48c0.8,0,1.5-0.7,1.5-1.5C49.5,24.3,48.8,23.6,48,23.6z M32.1,24.6
		c0.2-1.1,1.2-2,2.4-2c1.2,0,2.2,0.8,2.4,2c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4-0.1,0.7c-0.3,1-1.3,1.8-2.3,1.8s-2-0.7-2.3-1.8
		c-0.1-0.2-0.1-0.4-0.1-0.7C32.1,24.8,32.1,24.7,32.1,24.6z"
        />
        <path
          className="st0"
          d="M48,41.9H19.6c-0.6-2.4-2.8-4.1-5.3-4.1S9.6,39.5,9,41.9H2c-0.8,0-1.5,0.7-1.5,1.5C0.5,44.3,1.2,45,2,45h7.1
		c0.7,2.2,2.8,3.8,5.2,3.8s4.5-1.6,5.2-3.8H48c0.8,0,1.5-0.7,1.5-1.5C49.5,42.6,48.8,41.9,48,41.9z M11.9,42.9c0.2-1.1,1.2-2,2.4-2
		s2.2,0.8,2.4,2c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4-0.1,0.7c-0.3,1-1.3,1.8-2.3,1.8S12.3,45,12,44c-0.1-0.2-0.1-0.4-0.1-0.7
		C11.9,43.1,11.9,43,11.9,42.9z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
