import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M32,16.2c0.5,0,0.9,0,1.3,0c4.6,0,9.2,0,13.8,0c1.8,0,2.4,0.5,2.4,2.3c0,9.5,0,19,0,28.5
		c0,1.9-0.5,2.4-2.4,2.4c-14.8,0-29.6,0-44.3,0c-1.7,0-2.3-0.6-2.3-2.3c0-12.5,0-25,0-37.4c0-1.6,0.3-2,1.9-2.4c9-2.3,18-4.5,27-6.8
		c1.7-0.4,2.6,0.3,2.6,2c0,4,0,8,0,12C32,15.1,32,15.6,32,16.2z M11,45.9c0-0.4,0-0.8,0-1.2c0-2.3,0-4.7,0-7c0-1.7,0.6-2.3,2.3-2.3
		c2,0,4,0,6,0c1.6,0,2.2,0.6,2.2,2.2c0,2.4,0,4.8,0,7.2c0,0.4,0,0.7,0.1,1c2.4,0,4.6,0,6.8,0c0-13.8,0-27.6,0-41.3
		c-0.3,0-0.5,0.1-0.7,0.1C20.2,6.5,12.6,8.4,5,10.3C4.2,10.5,4,10.8,4,11.6c0,11.1,0,22.1,0,33.2c0,0.4,0,0.7,0.1,1.1
		C6.4,45.9,8.6,45.9,11,45.9z M45.9,45.9c0-8.8,0-17.4,0-26.1c-4.6,0-9.2,0-13.8,0c0,8.7,0,17.4,0,26.1
		C36.7,45.9,41.3,45.9,45.9,45.9z M14.6,39.1c0,2.3,0,4.6,0,6.9c1.2,0,2.2,0,3.3,0c0-2.3,0-4.6,0-6.9C16.7,39.1,15.7,39.1,14.6,39.1
		z"
        />
        <path
          className="st0"
          d="M16.3,32c-1.7,0-3.4,0-5.1,0c-1.2,0-2-0.7-2-1.7c0-1,0.8-1.8,1.9-1.8c3.4,0,6.8,0,10.1,0c1.3,0,2,0.7,2,1.8
		c0,1-0.8,1.7-2,1.7C19.6,32,17.9,32,16.3,32z"
        />
        <path
          className="st0"
          d="M11,24.9c-0.7,0-1.4,0.1-2,0c-0.9-0.1-1.4-0.7-1.5-1.5c-0.1-0.9,0.4-1.7,1.3-1.8c1.5-0.1,3-0.1,4.4,0
		c0.9,0.1,1.3,0.9,1.2,1.8c-0.1,0.9-0.6,1.4-1.5,1.5C12.3,25,11.7,24.9,11,24.9z"
        />
        <path
          className="st0"
          d="M21.5,18c-0.6,0-1.2,0-1.8,0c-1-0.1-1.7-0.8-1.7-1.8c0-0.9,0.8-1.7,1.8-1.7c1.2,0,2.4,0,3.5,0
		c1,0,1.7,0.7,1.7,1.7c0,0.9-0.6,1.7-1.6,1.8c-0.1,0-0.1,0-0.2,0C22.7,18,22.1,18,21.5,18C21.5,18,21.5,18,21.5,18z"
        />
        <path
          className="st0"
          d="M11,18c-0.6,0-1.1,0-1.7,0c-1.1-0.1-1.8-0.8-1.8-1.8c0-1,0.7-1.7,1.8-1.7c1.1,0,2.2,0,3.3,0
		c1.1,0,1.8,0.7,1.8,1.6c0,1-0.6,1.8-1.7,1.8C12.2,18,11.6,18,11,18z"
        />
        <path
          className="st0"
          d="M21.4,24.9c-0.6,0-1.3,0-1.9,0c-1-0.1-1.5-0.7-1.5-1.6c-0.1-0.9,0.4-1.6,1.2-1.7c1.5-0.1,3-0.1,4.5,0
		c0.9,0.1,1.3,0.8,1.2,1.7c0,0.9-0.5,1.5-1.4,1.6C22.9,25,22.2,24.9,21.4,24.9C21.4,24.9,21.4,24.9,21.4,24.9z"
        />
        <path
          className="st0"
          d="M39.1,39c-0.6,0-1.2,0-1.8,0c-1-0.1-1.7-0.7-1.7-1.6c0-1,0.5-1.8,1.5-1.8c1.3-0.1,2.6-0.1,3.9,0
		c1,0.1,1.6,0.9,1.5,1.8c-0.1,0.9-0.7,1.5-1.6,1.6C40.3,39,39.7,39,39.1,39z"
        />
        <path
          className="st0"
          d="M39,30.2c-0.6,0-1.2,0-1.8,0c-0.9-0.1-1.5-0.5-1.6-1.5c-0.1-0.9,0.3-1.8,1.3-1.9c1.5-0.1,3-0.1,4.4,0
		c0.9,0.1,1.4,1,1.3,1.9c-0.2,0.9-0.7,1.4-1.7,1.5C40.2,30.2,39.6,30.2,39,30.2C39,30.2,39,30.2,39,30.2z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
