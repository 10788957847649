import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <SvgIcon viewBox="0 0 50 50" className={classes.icon}>
      <g iextraneous="self">
        <path
          className="st0"
          d="M49.4,10c-0.7-5.1-5-9.1-10.2-9.5C33.5,0.1,28.3,4.2,27.5,10c-0.2,0-0.4,0-0.6,0c-7.4,0-14.8,0-22.3,0
		c-2.3,0-4.1,1.7-4.1,4c0,10.5,0,21,0,31.6c0,2.2,1.7,4,3.9,4c10.5,0,21.1,0,31.6,0c2.2,0,3.9-1.7,3.9-4c0-0.7,0-1.4,0-2.2
		c0-0.1,0.1-0.4,0.2-0.4c1-0.5,1.4-1.3,1.4-2.4c0-4.5,0-9.1,0-13.6c0-0.6-0.1-1-0.8-1.2c0-1.1,0-2.3,0-3.4
		C46.6,21,50.2,15.6,49.4,10z M38.3,46.1c-0.1,1.1-1.1,1.8-2.2,1.8c-0.3,0-0.5,0-0.8,0c-0.4-6.8-3.8-11.6-10.1-14.2
		c4.5-3.2,4.8-9.3,1.4-13c-3.3-3.5-8.7-3.7-12.2-0.4c-2,1.9-2.9,4.2-2.7,6.9c0.2,2.7,1.5,4.8,3.7,6.5c-3,1.1-5.5,2.9-7.3,5.5
		c-1.8,2.6-2.8,5.5-2.8,8.6c-2,0.4-3.2-1.1-3.2-2.7c0-10.3,0-20.6,0-30.9c0-1.8,0.9-2.7,2.7-2.7c7.3,0,14.6,0,22,0
		c0.2,0,0.4,0,0.5,0c0.4,5.7,3.3,9.2,8.7,10.9c0,1.1,0,2.2,0,3.3c-0.7,0.3-0.8,0.4-0.8,1.2c0,4.5,0,9,0,13.5c0,1.8,0.9,2.7,2.7,2.7
		c0.1,0,0.3,0,0.4,0C38.4,44.2,38.5,45.1,38.3,46.1z M37.7,25.8c0-1,0-2,0-3.1c0.5,0,1,0,1.5,0c0,1,0,2.1,0,3.1
		C38.7,25.8,38.2,25.8,37.7,25.8z M38.5,19.5c-4.4,0-7.9-3.5-8-7.9c0-4.4,3.5-7.9,7.9-7.9c4.3,0,7.9,3.5,7.9,7.8
		C46.4,15.9,42.8,19.5,38.5,19.5z"
        />
        <path
          className="st0"
          d="M38.2,6.8c0.6,0,0.9,0.3,1,0.7c0,0.5-0.3,0.8-0.9,0.8c-1.7,0.1-2.9,1.3-3.1,3.1c0,0.5-0.3,0.9-0.8,0.9
		c-0.5,0-0.8-0.4-0.8-0.9C33.7,9,35.8,6.9,38.2,6.8z"
        />
      </g>
    </SvgIcon>
  );
};

export default Icon;
